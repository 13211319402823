@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_1_item {
    margin-top: 0px;
}

.margem_2_item {
    margin-top: calc(35px * 1);
}

.margem_3_item {
    margin-top: calc(35px * 2);
}

.margem_4_item {
    margin-top: calc(35px * 3);
}

.margem_5_item {
    margin-top: calc(35px * 4);
}

.margem_6_item {
    margin-top: calc(35px * 5);
}

.margem_7_item {
    margin-top: calc(35px * 6);
}

/* SISTEMAS OUTROS */
.sistema_outros_BAT {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_BAT td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_BAT td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_BAT td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_BAT td:nth-child(3) {
    width: 94px;
}

.sistema_outros_BAT td:nth-child(4) {
    width: 106px;
}

.sistema_outros_BAT td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_BAT {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_BAT td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_BAT td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_BAT td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-BAT {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-BAT li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-BAT li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_BAT {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_BAT tr {
    height: 20px;
}

.assinatura_cliente_BAT tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_BAT {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_BAT tr {
    height: 20px;
}

.assinatura_responsavel_BAT tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}