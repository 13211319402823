.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 120px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

/* .type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 80px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 300px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
} */

.type3-content th:nth-child(7) {
    min-width: 115px;
}

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.icone-desatualizado {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.icone-ajuda-desatualizado {
    margin-left: 5px;
}




.esconder-ajuda-desatualizado {
    display: none;
}

.icone-ajuda-desatualizado:hover+.esconder-ajuda-desatualizado {
    display: block;
    position: absolute;
    margin-left: 200px;
    background-color: #fff;
    border: 2px solid #0083cc;
    border-radius: 5px;
    padding: 5px 10px;
}

.icone-ajuda-desatualizado:hover+.esconder-ajuda-desatualizado:after {
    content: '';
    position: absolute;
    transform: rotate(270deg); /* Equal to rotateZ(45deg) */
    bottom: 10px;
    left: -5px;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #0083cc;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

.icone-ajuda-desatualizado {
    cursor: pointer;
}