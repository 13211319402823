.type1-element {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.type1-top-line {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
}

.type1-cliente-top-line {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
}

.type1-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    width: 270px;
    border-bottom: 3px solid #C4C4C4;
    line-height: 36px;
    padding-left: 0;
}

.type1-element-link {
    color: inherit;
    text-decoration: inherit;
}

.type1-main-button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    margin-bottom: 5px;
    transition: background 0.2s;
    font-size: 18px;
}

.type1-main-button:hover {
    background: #eaeaea;
}

.type1-main-button:active {
    background: #fff;
}

.type1-main-button span{
    margin-left: 8px;
    font-size: 13.3px;
}


.type1-cliente-main-button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    margin-bottom: 5px;
    transition: background 0.2s;
    font-size: 18px;
}

.type1-cliente-main-button:hover {
    background: #eaeaea;
}

.type1-cliente-main-button:active {
    background: #fff;
}

.type1-cliente-main-button span{
    margin-left: 8px;
    font-size: 13.3px;
}

.tabela-clientes {
    width: 99%;
    border-collapse: collapse;
    margin-left: 1%;
}

.type1-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type1-content th:nth-child(1) {
    text-align: left;
    width: 80px;
}

.type1-content th:nth-child(2) {
    text-align: left;
    width: 350px;
}

.type1-content th:nth-child(3) {
    text-align: left;
    width: 180px;
}

.type1-content th:nth-child(4) {
    text-align: left;
    width: 220px;
}

.type1-content td {
    padding: 5px 0;
}

.type1-content tr {
    transition: background 0.2s;
}

.type1-content tr:hover {
    background: #eaeaea;
}


.type1-cliente-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type1-cliente-content th:nth-child(1) {
    text-align: left;
    width: 80px;
}

.type1-cliente-content th:nth-child(2) {
    text-align: left;
    width: 350px;
}

.type1-cliente-content th:nth-child(3) {
    text-align: left;
    width: 180px;
}

.type1-cliente-content th:nth-child(4) {
    text-align: left;
    width: 220px;
}

.type1-cliente-content td {
    padding: 5px 0;
}

.type1-cliente-content tr {
    transition: background 0.2s;
}

.type1-cliente-content tr:hover {
    background: #eaeaea;
}

.type1-functions {
    width: 245px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.type2-functions {
    width: 200px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.new-proposal1 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 135px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal1:hover {
    background: #1B6C1E;
}


.new-proposal2 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 155px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal2:hover {
    background: #1B6C1E;
}



@media (max-width: 600px) {
    .type1-cliente-main-button{
        font-size: 26px;
    }

    .type1-cliente-main-button span{
        margin-left: 4px;
        font-size: 13.3px;
    }

    .type1-cliente-content {
        max-height: 100%;
    }

    .type1-cliente-top-line {
        flex-wrap: wrap;
        height: 140px;
    }

    .type1-cliente-main-button {
        margin: -5px 0 5px 0;
    }

    .type1-cliente-content th:nth-child(1) {
        min-width: 50px;
    }

    .type1-cliente-content th:nth-child(2) {
        min-width: 200px;
    }

    .type1-cliente-content th:nth-child(3) {
        min-width: 150px;
    }

    .type1-cliente-content th:nth-child(4) {
        min-width: 125px;
    }
}