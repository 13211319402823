/* Página 6 */

.etapas_fracao {
    color: #223B7D;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 170px;
}

.etapas_valor_unidade {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 275px;
}

.etapas_valor_valor {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 315px;
    width: 120px;
}

.etapas_valor_valor li div {
    width: 100%;
    text-align: right;
}

.contato {
    color: #223B7D;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 6242px;
    left: 417px;
}

.contato li:nth-child(1) {
    font-weight: bold;
}

.contato_margem {
    margin-bottom: 17px;
}




.assinatura_cliente {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6490px;
    text-align: center
}

.assinatura_responsavel1 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6586px;
    text-align: center
}


.assinatura_responsavel2 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6605px;
    text-align: center
}
