.quantidade-gerador {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-gerador button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-gerador {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-gerador {
    width: 30%;
    min-width: 105px;
}

.proposal-button-gerador button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-gerador button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}