.proposal-container {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.proposal-client-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 18px;
    padding: 0px 15px;
}

.proposal-client-title table {
    border-collapse: collapse;
    border: none;
}

.proposal-client-title th {
    border: none;
    text-align: left;
}

.proposal-client-title td {
    padding-right: 30px;
    padding-left: 25px;
    min-width: 180px;
    border: none;
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
}
.proposal-element-body-functions-resumida {
    width: 40%;
    min-width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-resumida {
    width: 32%;
    min-width: 105px;
}


.proposal-button-resumida button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
}

.proposal-button-resumida button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}


.proposal-element-body-functions-resumida span {
    padding: 6px 9px;
    margin: 0 auto;
}

.proposal-element-body-functions-resumida button:hover {
    background: #eaeaea;
}

.proposal-element-body-functions-resumida button:active {
    background: #fff;
}

.proposal-element-narrow {
    width: 22%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.proposal-element-narrow-wrapper {
    width: 22%;
    min-height: 450px;
}

.proposal-element-narrow-wrapper form {
    height: 100%;
}

.proposal-subelement {
    width: 100%;
    height: calc(100% - 32px);
    min-height: 418px;
    background: white;
    border-radius: 0  0 5px 5px;
    padding: 15px 25px 20px;
}

.proposal-element-wide {
    width: 54%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 0px auto;
}

.proposal-element-wide-wrapper {
    width: 53%;
    min-height: 450px;
}

.proposal-element-title {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.proposal-element-title button {
    padding: 2px;
    border: none;
    background: inherit;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
}

.proposal-element-body {
    padding: 20px 0px;
}


.input-group-2 {
    display: block;
    margin-bottom: 7px;
}

.input-group-narrow-2 {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.input-group-narrow-2-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.form-simulation-desconto-weg {
    margin-bottom: 7px;
}

.form-simulation-desconto-weg-input {
    display: flex;
    justify-content: space-between;
}

.form-simulation-desconto-weg-input div {
    width: 22%;
}

.alert-save {
    margin-top: 15px;
    text-align: center;
}
.proposal-element-body label {
    font-size: 12px;
    margin-left: 4px;
}

.proposal-element-body input {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}







.submit-input {
    height: 35px;
    width: 100%;

    background: #fff;

    border-radius: 5px;
    border: 1px solid #000;

    display: flex;
    align-items: center;
    margin-top: 20px;
}

.submit-input p {
    margin-right: 5px;
}

.submit-input div {
    margin: 0px auto;

    display: flex;
    align-items: center;
}

.submit-input:hover {
    background: #eaeaea;
}







.proposal-element-body span {
    margin-left: -75px;
    font-size: 12px;
}

.proposal-element-body select {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}

.select-narrow {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.select-narrow-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.proposal-element-body {
    font-size: 12px;
}

.proposal-element-body table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.proposal-element-body table,
.proposal-element-body th,
.proposal-element-body td {
    border: 1px solid #BBBBBB;
    text-align: center;
}

.proposal-element-body th {
    font-weight: 600;
    padding: 5px;
}

.proposal-element-body td {
    padding: 5px;
}

.table-kit th:nth-child(2),
.table-kit td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
}


.table-kit-narrow {
    width: 16%;
}

.table-50p {
    width: 50%;
}

.table-results1 th {
    width: 50%;
    text-align: left;
    padding-left: 10px;
}

.table-results2 th {
    width: 50%;
}

.table-results3 th:nth-child(1) {
    width: 28%;
}

.proposal-same-line-tables{
    display: flex;
}

.proposal-table-dimensioning {
    width: 35%;
}

.proposal-table-dimensioning th {
    text-align: left;
}

.proposal-table-dimensioning td {
    width: 35%;
}


.proposal-table-total {
    margin-left: auto;
    width: 32%;
}




.type1-title-selection {
    /* margin-left: auto; */
    margin-right: 15px;
    height: 100%;
}

.type1-title-message {
    margin-left: auto;
    height: 100%;
}

.select-table {
    height: 30px;
    width: 190px;
}

.select-table form,
.select-table form select {
    margin-top: 0;
    height: 100%;
    width: 100%;
}

.select-table form select {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
}

.select-loading-wrapper {
    height: 30px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.select-loading {
    height: 30px;
    width: 30px;
    margin: auto;
}

.tabela-diferente {
    border-color: #efab00 !important;
}

.alerta-tabela {
    height: 30px;
    color: #efab00;
    display: flex;
    line-height: 30px;
    justify-content: center;
}

.alerta-tabela span {
    margin-top: 6px;
    margin-left: 7px;
}

.button3{
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3:hover {
    background: #eaeaea;
}

.button3-digitar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #efc700;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
}

.button3-digitar:hover {
    background: #c3a300;
}

.button3-restaurar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #d23a00;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3-restaurar:hover {
    background: #a02d01;
}

.alerta_formulario {
    margin-left: 5px;
    color: #ff0000;
}

.secao-formulario {
    margin-top: 27px;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}


.titulo_autotransformadores {
    padding-top: 30px;
    margin-top: 30px;
}

.valor_destaque_resumo {
    background: #e7e7e7;
}

.destaque_ganhos {
    background: #e7ffe6;
}

.wrapper-coluna-campos {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.coluna-campos {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.resumo-invalido {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #cc0000;
}