.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-controle {
    background: white;
    width: 25%;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 10px 10px 0 0;
}

.bloco1-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-responsaveis tr td {
    padding: 5px 0;
}


.bloco1-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.wrapper-blocos {
    display: flex;
}