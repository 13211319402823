.user-wrapper table {
    width: 100%;
    border-collapse: collapse;
}

.user-wrapper th {
    text-align: left;
    width: 190px;
}

.user-wrapper td {
    padding: 5px 0;
}

.user-wrapper {
    display: flex;
    justify-content: space-between;
}

.user-profile {
    line-height: 25px;
}

.user-image {
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-image img {
    height: 170px;
    border: 3px solid #C4C4C4;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.17);
}