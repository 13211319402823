@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden_AC {
    display: inline;
  }
}

@media screen {
  .hidden_AC {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
