.selecao-comparativo {
    width: 670px;
    display: flex;
    justify-content: space-between;
}

.wrapper-botao-comparar-tabela {
    margin-top: 4px;
}

.wrapper-comparativo {
    display: flex;
    justify-content: space-between;
}

.wrapper-table-comparar {
    width: calc(45%);
    overflow-x: auto;
    overflow-y: auto;
}

.wrapper-diferença {
    width: calc(10%);
    overflow-x: auto;
    overflow-y: auto;
    font-size: 14px !important;
}

.aumentoPrecoComparativo {
    background-color: rgb(255, 73, 73);
    color: white;
}

.reducaoPrecoComparativo {
    background-color: rgb(20, 224, 20);
}