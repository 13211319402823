@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');
.cabecalho_AC {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.cabecalho_AC_pagina2 {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 1348px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_total_AC_1_item { margin-top: 0px; }
.margem_total_AC_2_item { margin-top: calc(35px * 1); }
.margem_total_AC_3_item { margin-top: calc(35px * 2); }
.margem_total_AC_4_item { margin-top: calc(35px * 3); }
.margem_total_AC_5_item { margin-top: calc(35px * 4); }
.margem_total_AC_6_item { margin-top: calc(35px * 5); }
.margem_total_AC_7_item { margin-top: calc(35px * 6); }
.margem_total_AC_8_item { margin-top: calc(35px * 7); }
.margem_total_AC_9_item { margin-top: calc(35px * 8); }
.margem_total_AC_10_item { margin-top: calc(35px * 9); }
.margem_total_AC_11_item { margin-top: calc(35px * 10); }
.margem_total_AC_12_item { margin-top: calc(35px * 11); }
.margem_total_AC_13_item { margin-top: calc(35px * 12); }
.margem_total_AC_14_item { margin-top: calc(35px * 13); }


.margem_termos_AC_1_item { margin-top: 0px; }
.margem_termos_AC_2_item { margin-top: calc(35px * 1); }
.margem_termos_AC_3_item { margin-top: calc(35px * 2); }
.margem_termos_AC_4_item { margin-top: calc(35px * 3); }
.margem_termos_AC_5_item { margin-top: calc(35px * 4); }
.margem_termos_AC_6_item { margin-top: calc(35px * 5); }
.margem_termos_AC_7_item { margin-top: 1020px; }
.margem_termos_AC_8_item { margin-top: 1020px; }
.margem_termos_AC_9_item { margin-top: 1020px; }
.margem_termos_AC_10_item { margin-top: 1020px; }
.margem_termos_AC_11_item { margin-top: 1020px; }
.margem_termos_AC_12_item { margin-top: 1020px; }
.margem_termos_AC_13_item { margin-top: 1020px; }
.margem_termos_AC_14_item { margin-top: 1020px; }



.margem_contato_assinatura_AC_1_item { margin-top: 0px; }
.margem_contato_assinatura_AC_2_item { margin-top: calc(35px * 1); }
.margem_contato_assinatura_AC_3_item { margin-top: 770px; }
.margem_contato_assinatura_AC_4_item { margin-top: 770px; }
.margem_contato_assinatura_AC_5_item { margin-top: 770px; }
.margem_contato_assinatura_AC_6_item { margin-top: 770px; }
.margem_contato_assinatura_AC_7_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_8_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_9_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_10_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_11_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_12_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_13_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_14_item { margin-top: 1020px; }


/* SISTEMAS OUTROS */
.sistema_outros_AC {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_AC td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_AC td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_AC td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_AC td:nth-child(3) {
    width: 94px;
}

.sistema_outros_AC td:nth-child(4) {
    width: 106px;
}

.sistema_outros_AC td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_AC {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_AC td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_AC td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_AC td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes_AC {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 450px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes_AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato_AC {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 745px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato_AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato_AC li:nth-child(2) {
    font-weight: 600;
}


.contato-AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-AC li:nth-child(2) {
    font-weight: 600;
}

.destaque_termos_AC {
    padding: 0;
    font-weight: 600;
    margin-right: 5px;
}

.quebra_linha_termos_AC {
    height: 11px;
}

/* ASSINATURAS */

.assinatura_cliente_AC {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 765px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_AC tr {
    height: 20px;
}

.assinatura_cliente_AC tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_AC {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 850px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_AC tr {
    height: 20px;
}

.assinatura_responsavel_AC tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}