.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions-ac{
    min-width: 100px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content-ac {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content-ac th:nth-child(1),
.type3-content-ac th:nth-child(2) {
    min-width: 80px;
}
.type3-content-ac td:nth-child(3),
.type3-content-ac th:nth-child(3) {
    min-width: 250px;
}

.type3-content-ac th:nth-child(4),
.type3-content-ac th:nth-child(5),
.type3-content-ac th:nth-child(6) {
    min-width: 135px;
}

.type3-content-ac th:nth-child(7) {
    min-width: 180px;
}

.type3-content-ac th:nth-child(8) {
    min-width: 7px;
}

/* .type3-content-ac th:nth-child(9) {
    min-width: 220px;
} */

.type3-content-ac td {
    padding: 5px 0;
}

.type3-content-ac tr {
    transition: background 0.2s;
}

.type3-content-ac tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}