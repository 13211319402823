/* CSS pronto */
.input_file {
    outline: 2px dashed #ddd;
    outline-offset: -7px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 25px 40px 50px;
    text-align: center !important;
    margin: 0;
    width: 100%;
}

.input_file:hover{
    cursor: pointer;
    outline: 2px dashed #bbb;
}

.input_file:focus{
    outline: 2px dashed #bbb; 
    outline-offset: -7px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border: 1px solid #ddd;
}

.files {
    position: relative;
}

.files:before {
    position: absolute;
    bottom: -20px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " Ou arraste aqui. ";
    display: block;
    margin: 0 auto;
    color: #aaa;
    font-weight: 600;
    text-align: center;
}




/* CSS próprio */
.form-importar-tabela {
    width: 400px;
}

.input-group-importar {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: left;
}

.importar-input {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.importar-input-textarea {
    margin: 3px 0px;
    height: 250px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    overflow-x: auto;
    overflow-y: auto;
}

.importar-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.wrapper-table-importar {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.table-pre-visualizacao {
    width: 100%;
    font-size: 10px;
    border-collapse: collapse;
    text-align: center;
}


.table-pre-visualizacao td, .table-pre-visualizacao th {
    border: 1px solid #ddd;
    padding: 8px;
}

.table-pre-visualizacao tr:nth-child(even){background-color: #f2f2f2;}

.table-pre-visualizacao tr:hover {background-color: #ddd;}

.table-pre-visualizacao th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #aaa;
    color: white;
}

.importar-content {
    width: 100%;
    margin: auto;
    padding: 15px 0px;
    overflow-x: auto;
    overflow-y: auto;
}

.importar-tabela-vazia {
    font-size: 14px;
}

.wrapper-importar-superior {
    display: flex;
    flex-direction: row;
}

.importar-log {
    margin-left: 120px;
    width: 600px;
}

.exemplo-importar-tabela {
    font-size: 13px;
    color: #bfbfbf;
}

.importar-tabela-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}