@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }

  .grafico_comparativo {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3429px;
    left: 225px;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3726px;
    left: 58px;
    width: 651px;
    height: 325px;
  }

  /* .grafico_tarifa {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4041px;
    left: 58px;
    width: 651px;
    height: 332px;
  } */

  /* .grafico_tarifa span {
    visibility: visible;
    font-size: 16px;
    font-weight: bold;
    color: #1A2548;
    text-align: center;
    width: 91%;
    position: absolute;
    top: 108px;
    left: 9%;
  } */

  .grafico_geracao_25anos {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4071px;
    left: 58px;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: visible;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 4591px;
    left: 81px;
    width: 655px;
    height: 385px;
  }
}

@media screen {
  .hidden {
    display: none;
  }

  .grafico_comparativo {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 325px;
  }

  .grafico_geracao_25anos {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: hidden;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 0;
    left: 0;
    width: 655px;
    height: 385px;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

/* body {
  font-family: Noto Sans, sans-serif;
} */

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
