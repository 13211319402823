:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item-botao-salvar-como {
    display: flex;
    width: 24%;
    min-width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdownBotaoSalvar {
    position: absolute;
    top: 300px;
    width: 150px;
    z-index: 1;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }






.wrapper-botoes {
  width: 100%;
  min-width: 105px;
  display: flex;
}

.botao-opcao-padrao {
  width: 87%;
  padding: 6px 9px;
  height: 35px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 5px 0 0 5px;
}

.botao-opcao-padrao:disabled {
  border: 1px solid rgba(16, 16, 16, 0.3);
}

.botao-mais-opcoes {
  flex: 1;
  height: 35px;
  border-style:  solid solid solid none;
  border-color: #000;
  border-width: 1px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 0 5px 5px 0;
}

.botao-mais-opcoes:disabled {
  border-style:  solid solid solid none;
  border-color: rgba(16, 16, 16, 0.3);
  border-width: 1px;
}

.salvar-opcao {
  width: 100%;
  height: 35px;
  text-align: center;
  padding: 5px 0;
  border: 1px solid #000;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 5px;
}

.salvar-opcao:hover {
  background: #eaeaea;
}