.barra_navegacao {
    width: 100%;
    max-width: 1340px;
    margin: auto;
    padding: 26px 40px;
}

.lista_navegacao4 {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

.lista_navegacao3 {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

/* Navegação privilégio restrito */
.lista_navegacao2 {
    width: 100%;
    max-width: 580px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

.lista_navegacao1 {
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}


@media (max-width: 600px) {
    .barra_navegacao {
        width: 100%;
        /* max-width: 360px; */
    }
    
    ul li span {
        padding-left: 0px;
        visibility: hidden;
        width: 0px;
    }
}



li {
    display: flex;
    align-items: center;
}

ul li span {
    padding-left: 5px;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.text-link:hover,
.active {
    color: #1E518F;
}