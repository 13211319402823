@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    /* font-family: Source Sans Pro, sans-serif; */
}

body {
    font: 400 14px Source Sans Pro, sans-serif;
    /* background: #f0f0f5; */
    background: #e6e6eb;
    -webkit-font-smoothing: antialiased;
}

body, input, button, textarea, select {
    font-family: Source Sans Pro, sans-serif;
}

button {
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}


.body-container {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px;
}

.body-container-sem-margem-superior {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 0 40px 5vh;
}

.body-container-margem-inferior-menor {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px 0;
}

.body-container-2-sem-margem-superior {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 0 40px 5vh;
}

.body-container-2 {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px;
}

.body-container-2-margem-inferior-menor {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px 0;
}

.element-row-2 {
  width: 100%;
  max-width: 1340px;
  align-items: top;
  justify-content: space-between;
  display: flex;
  margin: auto;
  padding: 0 40px 40px;
}

.element-row-content-type1 {
  width: 49%;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.button-cancel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #cc0000;
  transition: background 0.2s;
  color: #fff;
}

.button-cancel:hover {
  background: #970000;
}

.button-submit {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #2AAC2F;
  transition: background 0.2s;
  color: #fff;
}

.button-submit:hover {
  background: #1B6C1E;
}

.button-submit-azul {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #0083cc;
  transition: background 0.2s;
  color: #fff;
}

.button-submit-azul:hover {
  background: #005A8C;
}

.button-submit-azul-ajustavel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background: #0083cc;
  transition: background 0.2s;
  color: #fff;
  padding: 0 15px;
  font-size: 12px;
}

.button-submit-azul-ajustavel:hover {
  background: #005A8C;
}

.button-submit-cinza {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #6a6a6a;
  transition: background 0.2s;
  color: #fff;
}

.button-submit-cinza:hover {
  background: rgb(74, 74, 74);
}

.button-submit-cinza-ajustavel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background: #6a6a6a;
  transition: background 0.2s;
  color: #fff;
  padding: 0 15px;
  font-size: 12px;
}

.button-submit-cinza-ajustavel:hover {
  background: rgb(74, 74, 74);
}

.button-submit-branco {
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #fff;
  transition: background 0.2s;
  border: solid 1px #C4C4C4;
}

.button-carregando {
  border-radius: 5px;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #fff;
  transition: background 0.2s;
  color: #fff;
  border: solid 1px #C4C4C4;
}

.edit1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #0083cc;
  margin: auto;
  transition: background 0.2s;
}

.edit1:hover {
  background: #005A8C;
}

.delete1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #cc0000;
  margin: auto;
  transition: background 0.2s;
}

.delete1:hover {
  background: #970000;
}

.error-form {
  border: 1px solid #cc0000 !important;
}

.botao-filtro-cliente {
  margin-left: 2px;
  border: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0);
}

.text-input-search-wraper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.text-input-search {
  width: 370px;
  margin: 0 auto 5px;
  height: 30px;
  border: 1px solid #dcdce6;
  border-radius: 5px;
  padding: 0 16px;
}

.text-input-search2 {
  width: 100%;
  margin: 0 auto 15px;
  height: 30px;
  border: 1px solid #dcdce6;
  border-radius: 5px;
  padding: 0 16px;
}



.search1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #828282;
  /* margin: auto; */
  transition: background 0.2s;
}

.search1:hover {
  background: #5a5a5a;
}

.texto-center {
  text-align: center !important;
}

.disabled-button {
  background: #a6a6a6 !important;
  color: #d9d9d9 !important;
}

.icone-actionTitle {
  margin-left: 5px;
  margin-bottom: -5px;
}

.alerta-tabela {
  height: 30px;
  color: #efab00;
  display: flex;
  line-height: 30px;
  justify-content: center;
}

.alerta-tabela span {
  margin-top: 6px;
  margin-left: 7px;
}

.alerta-tabela-tipo2 {
  height: 30px;
  background: #efab00;
  color: #ffffff !important;
  display: flex;
  line-height: 30px;
  justify-content: center;
  padding: 0px 10px;
  border-radius: 5px;
}

.alerta-tabela-tipo2 span {
  margin-top: 6px;
  margin-left: 7px;
}



@media (max-width: 600px) {
  .text-input-search {
    width: 100%;
    margin: 0;
  }

  .text-input-search-cliente {
    width: 100%;
    margin: 0;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
  }

  .text-input-search-wraper {
    width: 100%;
    margin: 10px 0;
  }

  .icone-ajuda {
    display: none;
  }
}
.modal-fundo {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
}

.modal-nova-proposta-branco {
    width: 500px;
    height: 560px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal-editar-proposta-branco {
    width: 350px;
    height: 390px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal4 {
    width: 500px;
    height: 560px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal-conteudo2 {
    padding: 20px;
}

.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.modal-proposta-branco-titulo {
    height: 34px;
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 15px;
}

.wrapper-titulo-proposta-branco {
    border-bottom: 3px solid #C4C4C4;
    padding-right: 15px;
    height: 35px;
}

.botao-fechar-modal {
    line-height: 35px;
    height: 17px;
    width: 17px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: auto;
    border: none;
    background: #a1a1a1;
    transition: background 0.2s;
    color: #ffffff;
}

.botao-fechar-modal:hover {
    background: #970000;
}

.modal-proposta-branco-botoes {
    display: flex;
    justify-content: space-between;
    width: 240px;
    margin-left: auto;
}


.modal-proposta-branco-cancelar {
    border-radius: 5px;
    border: none;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #cc0000;
    transition: background 0.2s;
    color: #ffffff;
}

.modal-proposta-branco-cancelar:hover {
    background: #970000;
}

.modal-proposta-branco-salvar {
    border-radius: 5px;
    border: none;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #2AAC2F;
    transition: background 0.2s;
    color: #ffffff;
}

.modal-proposta-branco-salvar:hover {
    background: #1B6C1E;
}

.modal-proposta-branco-salvar-carregando {
    border-radius: 5px;
    border: 1px solid #cacaca;
    height: 35px;
    width: 105px;
    background: #ffffff;
    display: flex;
    justify-content: center;
}

.modal-proposta-branco-salvar-carregando div {
    height: 100%;
    width: 50px;
}

.modal-proposta-branco-corpo {
    text-align: left;
}









.proposta-branco-edicao-tabela-bloqueada {
    cursor: not-allowed;
}

.proposta-branco-edicao-tabela-bloqueada th {
    color: #717171;
    padding-bottom: 10px;
}

.proposta-branco-edicao-tabela-bloqueada td {
    color: #8c8c8c;
    padding: 0 0 10px 15px;
}

.proposta-branco-edicao-tabela-bloqueada td:nth-child(2) {
    padding-right: 50px;
}

.proposta-branco-edicao-tabela {
    margin-bottom: 25px;
}

.proposta-branco-edicao-tabela th {
    padding: 10px 33px 10px 0;
}

.modal-proposta-branco-edicao-h4 {
    width: 100%;
    text-align: center;
    margin: 10px 0 10px;
    padding: 2px 0;
    border: 1px solid #c1c1c1;
    border-style: solid none;
}



.proposta-branco-edicao-tabela input {
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 12px;
}


.proposta-branco-edicao-tabela select {
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 12px;
}







.modal-proposta-branco-clientes {
    height: 300px;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 15px;
}

.modal-proposta-branco-clientes div {
    height: 35px;
    display: flex;
    align-items: center;
}

.modal-proposta-branco-clientes div input {
    height: 100%;
}

.modal-proposta-branco-clientes div label {
    margin-left: 10px;
}

.modal-proposta-branco-selecao-responsavel {
    display: flex;
    height: 40px;
    margin-bottom: 15px;
    align-items: center;
}

.modal-proposta-branco-selecao-responsavel select {
    margin-left: 30px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 14px;
}

.type4-functions {
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

/* Tabela com número da proposta e data da proposta */
.tabela1-proposta-branco th {
    padding-right: 15px;
}

.tabela1-proposta-branco td {
    padding-right: 70px;
}

.tabela1-proposta-branco tr {
    height: 35px;
}

/* Tabela com restante dos dados da proposta em branco */
.tabela2-proposta-branco tr {
    height: 35px;
}

.text-input-search3 {
    width: 100%;
    margin: 10px auto 10px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
}

.modal-fundo-visivel {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.0);
    display: flex;
}

.modal2 {
    width: 400px;
    height: 420px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}


.modal3 {
    width: 500px;
    height: 550px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal3 h4 {
    margin-bottom: 10px;
}

.modal-titulo {
    font-size: 17px;
    color: #000;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

.modal-conteudo {
    font-size: 14px;
    text-align: center;
    color: #000;
    width: 100%;
    padding: 10px 45px;
    margin-bottom: 35px;
}

.modal-acao {
    width: 65%;
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.modal-botao {
    width: 105px;
    padding: 6px 9px;
    height: 30px;
    line-height: 15px;
    ;
    border: 1px solid #000;
    text-align: center;
    background: #fff;
    border-radius: 5px;
}

.modal-header {
    height: 33px;
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 15px;
}

.wrapper-titulo {
    border-bottom: 3px solid #C4C4C4;
    padding-right: 15px;
    height: 34px;
}

.modal-body {
    text-align: left;
    height: 340px;
    overflow-x: auto;
    overflow-y: auto;
}

.modal-body table {
    width: 100%;
}

.modal-body table td {
    height: 35px;
}

.modal-body table td:nth-child(1) {
    width: 300px;
}

.modal-body tr {
    transition: background 0.2s;
}

.modal-body tr:hover {
    background: #eaeaea;
}

.modal-body table button {
    margin: auto;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.modal-body table button:hover {
    background: #eaeaea;
}

.modal-body table button:active {
    background: #fff;
}

.modal-nova-proposta-cadastro-cliente {
    margin-top: 30px;
    padding-right: 18px;
    width: 100%;
    text-align: right;
    font-size: 11px;
}

.modal-nova-proposta-cadastro-cliente a {
    color: #0083cc;
}

.modal-form {
    width: 500px;
    height: 430px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cliente {
    width: 500px;
    height: 430px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cliente form {
    margin-top: 25px;
}

/* .modal-cliente-entrada {
    display: flex;
    height: 40px;
    margin-bottom: 15px;
    align-items: center;
}

.modal-cliente-entrada label {
    font-size: 12px;
    margin-left: 4px;
}

.modal-cliente-entrada select input {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    flex-grow: 1;
    padding: 0px 5px;
    font-size: 12px;
} */

.modal-cliente-input {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 70%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.modal-cliente-input-narrow {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 55%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.modal-cliente-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 70%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.modal-cliente-radio-grupo {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
}

.modal-cliente-radio {
    align-items: center;
    display: flex;
}

.modal-cliente-radio label {
    margin-left: 5px;
}


.modal-cliente-submit-carregando {
    border-radius: 5px;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #fff;
    transition: background 0.2s;
    border: 1px solid rgba(16, 16, 16, 0.3)
}

.modal-cliente-submit-carregando:hover {
    background: #eaeaea;
}


/* MODAL SALVAR COMO */
.modal-salvar-como {
    width: 500px;
    height: 240px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-salvar-como-form-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.modal-salvar-como-input-group {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    align-items: center;
}

.modal-salvar-como-input {
    margin: 0 0 0 10px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 110px;
    padding: 0px 5px;
    font-size: 14px;
    text-align: center;
}


.modal-salvar-como-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
}

.modal-salvar-como-botoes button {
    margin-left: 40px;
    color: #fff;
}

.modal-corpo-editar-informacoes-comuns {
    height: 290px;
    overflow-y: auto;
    margin-top: -5px;
}

.modal-editar-informacoes-comuns-wrapper-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
}

.modal-editar-informacoes-comuns-wrapper-botoes button {
    margin-left: 40px;
}

.esconder-ajuda {
    display: none;
}

.icone-ajuda:hover+.esconder-ajuda {
    display: block;
    position: absolute;
    /* color: red; */
    /* left: 0; */
    /* top: 0; */
    margin-top: -37px;
    margin-left: 350px;
    background-color: #fff;
    border: 2px solid #0083cc;
    border-radius: 5px;
    padding: 5px 10px;
}

.icone-ajuda:hover+.esconder-ajuda:after {
    content: '';
    position: absolute;
    transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    bottom: -10px;
    left: 35px;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #0083cc;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

.icone-ajuda {
    color: #dcdce6;
    height: 100%;
    padding: 4px;
    margin: auto 0;
    margin-left: 5px;
    cursor: pointer;
}

.modal-cadastrar-novo-usuario {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal-form-2 {
    width: 1050px;
    height: 750px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cadastrar-novo-usuario-bloco1 {
    width: 55%;
}

.modal-cadastrar-novo-usuario-bloco2 {
    width: 35%;
    padding: 20px;
    border: 1px solid #C4C4C4;
}

.previsualizacao-perfil {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previsualizacao-perfil img {
    width: 170px;
    height: 170px;
    border: 3px solid #C4C4C4;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.17);
}

.modal-cadastrar-novo-usuario-base {
    width: 100%;
    padding-top: 20px;
}
header {
    background: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    width: 100%;
}

.header-top {
    width: 100%;
    max-width: 1340px;
    align-items: center;
    display: flex;
    margin: auto;
    padding: 11px 40px;
}

.header-search {
    width: 300px;
    margin-left: 40px;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img {
    height: 49px;
}

.header-right-div {
    display: flex;
    width: 100px;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;
}

.header-right-div-single {
    display: flex;
    width: 100px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
}

.header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    width: 27px;
    background: none;
    border: none;
}

.header-user {
    height: 40px;
}

.header-user img {
    height: 100%;
    border-radius: 50%;
}

.header-user-menu {
    width: 150px;
    position: absolute;
    
}

.header-navbar {
    border-top: 1px solid rgba(0,0,0,.05);
}

@media (min-width: 780px) {


}
.barra_navegacao {
    width: 100%;
    max-width: 1340px;
    margin: auto;
    padding: 26px 40px;
}

.lista_navegacao4 {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

.lista_navegacao3 {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

/* Navegação privilégio restrito */
.lista_navegacao2 {
    width: 100%;
    max-width: 580px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}

.lista_navegacao1 {
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    justify-content: space-between;
}


@media (max-width: 600px) {
    .barra_navegacao {
        width: 100%;
        /* max-width: 360px; */
    }
    
    ul li span {
        padding-left: 0px;
        visibility: hidden;
        width: 0px;
    }
}



li {
    display: flex;
    align-items: center;
}

ul li span {
    padding-left: 5px;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.text-link:hover,
.active {
    color: #1E518F;
}
.loading-wraper {
    /* height: 500px; */

}

.loading {
    height: 100%;
    width: 100%;
    max-width: 220px;
    min-width: 200px;



    font-size: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    margin: auto;
    /* border: 1px solid #000; */
}

.loading p{
    margin: auto;
    width: 100%;
    text-align: center;
}
.dynamic-loading-wrapper {
    height: 100%;
    width: 100%;
    margin: auto;
}

.dynamic-loading {
    height: 100%;
    width: 100%;


    background: inherit;
    display: flex;
    align-items: center;
    margin: auto;
}

.white-background {
    background: #ffffff;
    
    border-radius: 5px;
    border: solid 1px #C4C4C4;
    color: inherit;
    text-align: center;
    line-height: 44px;
    height: 35px;
    width: 35px;
    margin: auto;

}
:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    display: flex;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 59px;
    width: 170px;
    transform: translateX(-40%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 0.35rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    box-sizing: content-box;
    z-index: 50;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.1rem;
    box-sizing: content-box;
    background: #FFFFFF;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }

  .background-click {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(126, 126, 126, 0.15)
  }
:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    display: flex;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 59px;
    width: 170px;
    transform: translateX(-40%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 0.35rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    box-sizing: content-box;
    z-index: 50;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.1rem;
    box-sizing: content-box;
    background: #FFFFFF;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }

  .background-click {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(126, 126, 126, 0.15)
  }
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }

  .grafico_comparativo {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3429px;
    left: 225px;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3726px;
    left: 58px;
    width: 651px;
    height: 325px;
  }

  /* .grafico_tarifa {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4041px;
    left: 58px;
    width: 651px;
    height: 332px;
  } */

  /* .grafico_tarifa span {
    visibility: visible;
    font-size: 16px;
    font-weight: bold;
    color: #1A2548;
    text-align: center;
    width: 91%;
    position: absolute;
    top: 108px;
    left: 9%;
  } */

  .grafico_geracao_25anos {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4071px;
    left: 58px;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: visible;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 4591px;
    left: 81px;
    width: 655px;
    height: 385px;
  }
}

@media screen {
  .hidden {
    display: none;
  }

  .grafico_comparativo {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 325px;
  }

  .grafico_geracao_25anos {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: hidden;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 0;
    left: 0;
    width: 655px;
    height: 385px;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

/* body {
  font-family: Noto Sans, sans-serif;
} */

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.identificacao_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    left: 52px;
}

.data_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    right: 90px;
    text-align: right;
}

.cliente_economia {
    font-weight: normal;
    font-size: 26px;
    color: #746E6E;
    position: absolute;
    top: 408px;
    left: 52px;
}

.lista_resumo_capa {
    font-weight: normal;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    position: absolute;
    top: 556px;
    left: 61px;
    width: 350px;
    height: 550px;
    display: inline;
}

.lcoe {
    font-weight: normal;
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: 623px;
    right: 155px;
}
.premissa1 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    width: 150px;
    text-align: center;
    position: absolute;
    top: 2355px;
    left: 134px;
    width: 100px;
    text-align: center;
}

.premissa2 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 284px;
    width: 100px;
    text-align: center;
}

.premissa3 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 418px;
    width: 137px;
    text-align: center;
}

.premissa4 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 560px;
    width: 66px;
    text-align: center;
}

.dados_sistema {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2435px;
    left: 342px;
}

.estimativas_unidade {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2614px;
    left: 342px;
}

.estimativas_valor {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2614px;
    left: 360px;
    display: inline;
    width: 120px;
    text-align: right;
}

.estimativas_valor li div {
    width: 100%;
    text-align: right;
}

.analise_investimento_unidade {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    display: inline;
    position: absolute;
    top: 2810px;
    left: 342px;
}

.analise_investimento_valor {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2810px;
    left: 365px;
    display: inline;
    width: 100px;
    text-align: right;
}

.analise_investimento_valor li div {
    width: 100%;
    text-align: right;
}

.payback {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2850px;
    left: 334px;
    width: 135px;
    text-align: center;
}

.tir_escalonado {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 349px;
    width: 40px;
    text-align: center;
}

.vpl_escalonado_unidade {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 395px;
}

.vpl_escalonado_valor {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 410px;
    width: 100px;
    text-align: right;
}

.tma {
    color: #044875;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    top: 2916px;
    right: 150px;
}

.impacto_ambiental {
    color: #305123;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 3085px;
    left: 342px;
    text-align: left;
    line-height: 59px;
}
.titulo_grafico {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 0px;
    color: #5698D3;
    font-size: 17.6px;
}
.inversores_modelo_wrapper {
    position: absolute;
    top: 5048px;
    left: 0;
    width: 100%;
}

.inversores_modelo {
    color: #223B7D;
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_modelo div {
    width: 214px;
    font-weight: bold;
    text-align: center;
}

.inversores_imagem_wrapper {
    position: absolute;
    top: 5076px;
    left: 0;
    width: 100%;
}

.inversores_imagem {
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_imagem img {
    height: 145px;
}

.garantias {
    color: #404040;
    font-weight: bold;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 5298px;
    left: 208px;
    line-height: 42px;
    width: 140px;
}

.garantias li div {
    width: 100%;
    text-align: center;
}

.garantias li div p {
    line-height: 18px;
}

.outras_informacoes {
    color: #404040;
    font-weight: bold;
    font-size: 15px;
    display: inline;
    position: absolute;
    top: 5294px;
    left: 615px;
    line-height: 57px;
    width: 90px;
}

.outras_informacoes li div {
    width: 100%;
    text-align: center;
}
/* Página 6 */

.etapas_fracao {
    color: #223B7D;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 170px;
}

.etapas_valor_unidade {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 275px;
}

.etapas_valor_valor {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 315px;
    width: 120px;
}

.etapas_valor_valor li div {
    width: 100%;
    text-align: right;
}

.contato {
    color: #223B7D;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 6242px;
    left: 417px;
}

.contato li:nth-child(1) {
    font-weight: bold;
}

.contato_margem {
    margin-bottom: 17px;
}




.assinatura_cliente {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6490px;
    text-align: center
}

.assinatura_responsavel1 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6586px;
    text-align: center
}


.assinatura_responsavel2 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6605px;
    text-align: center
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }
}

@media screen {
  .hidden {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_1_item {
    margin-top: 0px;
}

.margem_2_item {
    margin-top: calc(35px * 1);
}

.margem_3_item {
    margin-top: calc(35px * 2);
}

.margem_4_item {
    margin-top: calc(35px * 3);
}

.margem_5_item {
    margin-top: calc(35px * 4);
}

.margem_6_item {
    margin-top: calc(35px * 5);
}

.margem_7_item {
    margin-top: calc(35px * 6);
}

/* SISTEMAS OUTROS */
.sistema_outros_BAT {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_BAT td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_BAT td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_BAT td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_BAT td:nth-child(3) {
    width: 94px;
}

.sistema_outros_BAT td:nth-child(4) {
    width: 106px;
}

.sistema_outros_BAT td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_BAT {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_BAT td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_BAT td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_BAT td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-BAT {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-BAT li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-BAT li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_BAT {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_BAT tr {
    height: 20px;
}

.assinatura_cliente_BAT tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_BAT {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_BAT tr {
    height: 20px;
}

.assinatura_responsavel_BAT tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }
}

@media screen {
  .hidden {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_1_item {
    margin-top: 0px;
}

.margem_2_item {
    margin-top: calc(35px * 1);
}

.margem_3_item {
    margin-top: calc(35px * 2);
}

.margem_4_item {
    margin-top: calc(35px * 3);
}

.margem_5_item {
    margin-top: calc(35px * 4);
}

.margem_6_item {
    margin-top: calc(35px * 5);
}

.margem_7_item {
    margin-top: calc(35px * 6);
}

/* SISTEMAS OUTROS */
.sistema_outros_GR {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_GR td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_GR td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_GR td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_GR td:nth-child(3) {
    width: 94px;
}

.sistema_outros_GR td:nth-child(4) {
    width: 106px;
}

.sistema_outros_GR td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_GR {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_GR td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_GR td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_GR td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-GR {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-GR li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-GR li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_GR {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_GR tr {
    height: 20px;
}

.assinatura_cliente_GR tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_GR {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_GR tr {
    height: 20px;
}

.assinatura_responsavel_GR tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }
}

@media screen {
  .hidden {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_1_item {
    margin-top: 0px;
}

.margem_2_item {
    margin-top: calc(35px * 1);
}

.margem_3_item {
    margin-top: calc(35px * 2);
}

.margem_4_item {
    margin-top: calc(35px * 3);
}

.margem_5_item {
    margin-top: calc(35px * 4);
}

.margem_6_item {
    margin-top: calc(35px * 5);
}

.margem_7_item {
    margin-top: calc(35px * 6);
}

/* SISTEMAS OUTROS */
.sistema_outros_OM {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_OM td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_OM td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_OM td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_OM td:nth-child(3) {
    width: 94px;
}

.sistema_outros_OM td:nth-child(4) {
    width: 106px;
}

.sistema_outros_OM td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_OM {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_OM td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_OM td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_OM td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-OM {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-OM li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-OM li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_OM {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_OM tr {
    height: 20px;
}

.assinatura_cliente_OM tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_OM {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_OM tr {
    height: 20px;
}

.assinatura_responsavel_OM tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }
}

@media screen {
  .hidden {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_1_item {
    margin-top: 0px;
}

.margem_2_item {
    margin-top: calc(35px * 1);
}

.margem_3_item {
    margin-top: calc(35px * 2);
}

.margem_4_item {
    margin-top: calc(35px * 3);
}

.margem_5_item {
    margin-top: calc(35px * 4);
}

.margem_6_item {
    margin-top: calc(35px * 5);
}

.margem_7_item {
    margin-top: calc(35px * 6);
}

/* SISTEMAS OUTROS */
.sistema_outros_EV {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_EV td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_EV td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_EV td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_EV td:nth-child(3) {
    width: 94px;
}

.sistema_outros_EV td:nth-child(4) {
    width: 106px;
}

.sistema_outros_EV td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_EV {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_EV td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_EV td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_EV td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-EV {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-EV li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-EV li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_EV {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_EV tr {
    height: 20px;
}

.assinatura_cliente_EV tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_EV {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_EV tr {
    height: 20px;
}

.assinatura_responsavel_EV tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }
}

@media screen {
  .hidden {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Montserrat Regular
@font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat/Montserrat-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
} */

/* Montserrat Medium */
/* @font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat/Montserrat-Medium.woff2") format('woff2');
    font-weight: 500;
    font-style: normal;
} */

/* Montserrat SemiBold */
/* @font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat/Montserrat-SemiBold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
} */

/* Montserrat Bold */
/* @font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat/Montserrat-Bold.woff2") format('woff2');
    font-weight: 700;
    font-style: normal;
} */

/* Montserrat ExtraBold */
/* @font-face {
    font-family: "Montserrat";
    src: url("../../assets/Montserrat/Montserrat-ExtraBold.woff2") format('woff2');
    font-weight: 800;
    font-style: normal;
} */


.cabecalho {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* SISTEMAS OUTROS */
.sistema_outros_NB {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_NB td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_NB td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_NB td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_NB td:nth-child(3) {
    width: 94px;
}

.sistema_outros_NB td:nth-child(4) {
    width: 106px;
}

.sistema_outros_NB td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_NB {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 385px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_NB td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_NB td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_NB td:nth-child(2) {
    width: 109px;
}

.fonte_item_reduzida {
    font-size: 10px;
}


.td_longo {
    height: 75px !important;
}


/* TERMOS E CONTATO */

.termos_condicoes {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 485px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-NB {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato-NB li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-NB li:nth-child(2) {
    font-weight: 600;
}


/* ASSINATURAS */

.assinatura_cliente_NB {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 585px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_NB tr {
    height: 20px;
}

.assinatura_cliente_NB tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_NB {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 670px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_NB tr {
    height: 20px;
}

.assinatura_responsavel_NB tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden_AC {
    display: inline;
  }
}

@media screen {
  .hidden_AC {
    display: none;
  }

}

@page {
  size: auto;
  margin: 0mm;
}

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cabecalho_AC {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 227px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.cabecalho_AC_pagina2 {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 1348px;
    left: 170px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

/* MARGENS */
.margem_total_AC_1_item { margin-top: 0px; }
.margem_total_AC_2_item { margin-top: calc(35px * 1); }
.margem_total_AC_3_item { margin-top: calc(35px * 2); }
.margem_total_AC_4_item { margin-top: calc(35px * 3); }
.margem_total_AC_5_item { margin-top: calc(35px * 4); }
.margem_total_AC_6_item { margin-top: calc(35px * 5); }
.margem_total_AC_7_item { margin-top: calc(35px * 6); }
.margem_total_AC_8_item { margin-top: calc(35px * 7); }
.margem_total_AC_9_item { margin-top: calc(35px * 8); }
.margem_total_AC_10_item { margin-top: calc(35px * 9); }
.margem_total_AC_11_item { margin-top: calc(35px * 10); }
.margem_total_AC_12_item { margin-top: calc(35px * 11); }
.margem_total_AC_13_item { margin-top: calc(35px * 12); }
.margem_total_AC_14_item { margin-top: calc(35px * 13); }


.margem_termos_AC_1_item { margin-top: 0px; }
.margem_termos_AC_2_item { margin-top: calc(35px * 1); }
.margem_termos_AC_3_item { margin-top: calc(35px * 2); }
.margem_termos_AC_4_item { margin-top: calc(35px * 3); }
.margem_termos_AC_5_item { margin-top: calc(35px * 4); }
.margem_termos_AC_6_item { margin-top: calc(35px * 5); }
.margem_termos_AC_7_item { margin-top: 1020px; }
.margem_termos_AC_8_item { margin-top: 1020px; }
.margem_termos_AC_9_item { margin-top: 1020px; }
.margem_termos_AC_10_item { margin-top: 1020px; }
.margem_termos_AC_11_item { margin-top: 1020px; }
.margem_termos_AC_12_item { margin-top: 1020px; }
.margem_termos_AC_13_item { margin-top: 1020px; }
.margem_termos_AC_14_item { margin-top: 1020px; }



.margem_contato_assinatura_AC_1_item { margin-top: 0px; }
.margem_contato_assinatura_AC_2_item { margin-top: calc(35px * 1); }
.margem_contato_assinatura_AC_3_item { margin-top: 770px; }
.margem_contato_assinatura_AC_4_item { margin-top: 770px; }
.margem_contato_assinatura_AC_5_item { margin-top: 770px; }
.margem_contato_assinatura_AC_6_item { margin-top: 770px; }
.margem_contato_assinatura_AC_7_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_8_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_9_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_10_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_11_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_12_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_13_item { margin-top: 1020px; }
.margem_contato_assinatura_AC_14_item { margin-top: 1020px; }


/* SISTEMAS OUTROS */
.sistema_outros_AC {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 375px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_AC td {
    background: #F6F6F6;
    height: 35px;
    border-bottom: 1pt solid #C5C5C5;
}

.sistema_outros_AC td:nth-child(1) {
    color: #0061C1;
    width: 48px;
}

.sistema_outros_AC td:nth-child(2) {
    text-align: left;
    padding-left: 38px;
    width: 320px;
}

.sistema_outros_AC td:nth-child(3) {
    width: 94px;
}

.sistema_outros_AC td:nth-child(4) {
    width: 106px;
}

.sistema_outros_AC td:nth-child(5) {
    width: 109px;
}

.sistema_outros_total_AC {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    position: absolute;
    top: 430px;
    left: 520px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: center;
    border-collapse: collapse;
}

.sistema_outros_total_AC td {
    background: #00569D;
    height: 35px;
}
.sistema_outros_total_AC td:nth-child(1) {
    width: 106px;
}

.sistema_outros_total_AC td:nth-child(2) {
    width: 109px;
}

/* TERMOS E CONTATO */

.termos_condicoes_AC {
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: #000000;
    position: absolute;
    top: 450px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.termos_condicoes_AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato_AC {
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: #000000;
    position: absolute;
    top: 745px;
    left: 58px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.contato_AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato_AC li:nth-child(2) {
    font-weight: 600;
}


.contato-AC li:nth-child(1) {
    font-size: 17px;
    line-height: 35px;
    font-weight: 600;
}

.contato-AC li:nth-child(2) {
    font-weight: 600;
}

.destaque_termos_AC {
    padding: 0;
    font-weight: 600;
    margin-right: 5px;
}

.quebra_linha_termos_AC {
    height: 11px;
}

/* ASSINATURAS */

.assinatura_cliente_AC {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 765px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_cliente_AC tr {
    height: 20px;
}

.assinatura_cliente_AC tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}

.assinatura_responsavel_AC {
    font-weight: normal;
    font-size: 11.5px;
    color: #000000;
    position: absolute;
    top: 850px;
    left: 400px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border-top: 1pt solid #000000;
    border-collapse: collapse;
    width: 340px;
    text-align: center;
}

.assinatura_responsavel_AC tr {
    height: 20px;
}

.assinatura_responsavel_AC tr:nth-child(1) {
    height: 23px;
    vertical-align: bottom;
}
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    background: #FFFFFF;
    -webkit-print-color-adjust: exact;
  }
  .hidden {
    display: inline;
  }

  .grafico_comparativo {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3429px;
    left: 225px;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 3726px;
    left: 58px;
    width: 651px;
    height: 325px;
  }

  /* .grafico_tarifa {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4041px;
    left: 58px;
    width: 651px;
    height: 332px;
  } */

  /* .grafico_tarifa span {
    visibility: visible;
    font-size: 16px;
    font-weight: bold;
    color: #1A2548;
    text-align: center;
    width: 91%;
    position: absolute;
    top: 108px;
    left: 9%;
  } */

  .grafico_geracao_25anos {
    visibility: visible;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 4071px;
    left: 58px;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: visible;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 4591px;
    left: 81px;
    width: 655px;
    height: 385px;
  }
}

@media screen {
  .hidden {
    display: none;
  }

  .grafico_comparativo {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 483px;
    height: 280px;
  }

  .grafico_payback {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 325px;
  }

  .grafico_geracao_25anos {
    visibility: hidden;
    background: #fff;
    border: 1px solid #006CB5;
    position: absolute;
    top: 0;
    left: 0;
    width: 651px;
    height: 319px;
  }
  
  .grafico_previsao {
    visibility: hidden;
    background: #fff;
    position: absolute;
    border: 1px solid #006CB5;
    top: 0;
    left: 0;
    width: 655px;
    height: 385px;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

/* body {
  font-family: Noto Sans, sans-serif;
} */

.page {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.identificacao_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    left: 52px;
}

.data_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    right: 90px;
    text-align: right;
}

.cliente_economia {
    font-weight: normal;
    font-size: 26px;
    color: #746E6E;
    position: absolute;
    top: 408px;
    left: 52px;
}

.lista_resumo_capa {
    font-weight: normal;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    position: absolute;
    top: 556px;
    left: 61px;
    width: 350px;
    height: 550px;
    display: inline;
}

.lcoe {
    font-weight: normal;
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: 623px;
    right: 155px;
}
.premissa1 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    width: 150px;
    text-align: center;
    position: absolute;
    top: 2355px;
    left: 134px;
    width: 100px;
    text-align: center;
}

.premissa2 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 284px;
    width: 100px;
    text-align: center;
}

.premissa3 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 418px;
    width: 137px;
    text-align: center;
}

.premissa4 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 560px;
    width: 66px;
    text-align: center;
}

.dados_sistema {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2435px;
    left: 342px;
}

.estimativas_unidade {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2614px;
    left: 342px;
}

.estimativas_valor {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2614px;
    left: 360px;
    display: inline;
    width: 120px;
    text-align: right;
}

.estimativas_valor li div {
    width: 100%;
    text-align: right;
}

.analise_investimento_unidade {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    display: inline;
    position: absolute;
    top: 2810px;
    left: 342px;
}

.analise_investimento_valor {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2810px;
    left: 365px;
    display: inline;
    width: 100px;
    text-align: right;
}

.analise_investimento_valor li div {
    width: 100%;
    text-align: right;
}

.payback {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2850px;
    left: 334px;
    width: 135px;
    text-align: center;
}

.tir_escalonado {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 349px;
    width: 40px;
    text-align: center;
}

.vpl_escalonado_unidade {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 395px;
}

.vpl_escalonado_valor {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 410px;
    width: 100px;
    text-align: right;
}

.tma {
    color: #044875;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    top: 2916px;
    right: 158px;
}

.impacto_ambiental {
    color: #305123;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 3085px;
    left: 342px;
    text-align: left;
    line-height: 59px;
}
.titulo_grafico {
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 0px;
    color: #5698D3;
    font-size: 17.6px;
}
.inversores_modelo_wrapper {
    position: absolute;
    top: 5048px;
    left: 0;
    width: 100%;
}

.inversores_modelo {
    color: #223B7D;
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_modelo div {
    width: 214px;
    font-weight: bold;
    text-align: center;
}

.inversores_imagem_wrapper {
    position: absolute;
    top: 5076px;
    left: 0;
    width: 100%;
}

.inversores_imagem {
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_imagem img {
    height: 145px;
}

.garantias {
    color: #404040;
    font-weight: bold;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 5298px;
    left: 208px;
    line-height: 42px;
    width: 140px;
}

.garantias li div {
    width: 100%;
    text-align: center;
}

.garantias li div p {
    line-height: 18px;
}

.outras_informacoes {
    color: #404040;
    font-weight: bold;
    font-size: 15px;
    display: inline;
    position: absolute;
    top: 5294px;
    left: 615px;
    line-height: 57px;
    width: 90px;
}

.outras_informacoes li div {
    width: 100%;
    text-align: center;
}
/* Página 6 */

.etapas_fracao {
    color: #223B7D;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 170px;
}

.etapas_valor_unidade {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 275px;
}

.etapas_valor_valor {
    color: #2373B5;
    font-weight: bold;
    font-size: 15px;
    line-height: 49px;
    display: inline;
    position: absolute;
    top: 5744px;
    left: 315px;
    width: 120px;
}

.etapas_valor_valor li div {
    width: 100%;
    text-align: right;
}

.contato {
    color: #223B7D;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 6242px;
    left: 417px;
}

.contato li:nth-child(1) {
    font-weight: bold;
}

.contato_margem {
    margin-bottom: 17px;
}




.assinatura_cliente {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6490px;
    text-align: center
}

.assinatura_responsavel1 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6586px;
    text-align: center
}


.assinatura_responsavel2 {
    color: #223B7D;
    font-weight: normal;
    width: 70%;
    margin: 0 0 0 16.6%;
    position: absolute;
    top: 6605px;
    text-align: center
}

:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    display: flex;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 59px;
    width: 185px;
    transform: translateX(-40%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 0.35rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    box-sizing: content-box;
    z-index: 50;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.1rem;
    box-sizing: content-box;
    background: #FFFFFF;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }

  .background-click {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(126, 126, 126, 0.15)
  }
:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item-botao-salvar-como {
    display: flex;
    width: 24%;
    min-width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdownBotaoSalvar {
    position: absolute;
    top: 300px;
    width: 150px;
    z-index: 1;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }






.wrapper-botoes {
  width: 100%;
  min-width: 105px;
  display: flex;
}

.botao-opcao-padrao {
  width: 87%;
  padding: 6px 9px;
  height: 35px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 5px 0 0 5px;
}

.botao-opcao-padrao:disabled {
  border: 1px solid rgba(16, 16, 16, 0.3);
}

.botao-mais-opcoes {
  flex: 1 1;
  height: 35px;
  border-style:  solid solid solid none;
  border-color: #000;
  border-width: 1px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 0 5px 5px 0;
}

.botao-mais-opcoes:disabled {
  border-style:  solid solid solid none;
  border-color: rgba(16, 16, 16, 0.3);
  border-width: 1px;
}

.salvar-opcao {
  width: 100%;
  height: 35px;
  text-align: center;
  padding: 5px 0;
  border: 1px solid #000;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 5px;
}

.salvar-opcao:hover {
  background: #eaeaea;
}
:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item-botao-salvar-como {
    display: flex;
    /* width: 24%; */
    min-width: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdownBotaoSalvar {
    position: absolute;
    top: 302px;
    width: 160px;
    z-index: 1;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }






.wrapper-botoes {
  width: 100%;
  min-width: 105px;
  display: flex;
}

.botao-opcao-padrao {
  width: 87%;
  padding: 6px 9px;
  height: 35px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 5px 0 0 5px;
}

.botao-opcao-padrao:disabled {
  border: 1px solid rgba(16, 16, 16, 0.3);
}

.botao-mais-opcoes {
  flex: 1 1;
  height: 35px;
  border-style:  solid solid solid none;
  border-color: #000;
  border-width: 1px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 0 5px 5px 0;
}

.botao-mais-opcoes:disabled {
  border-style:  solid solid solid none;
  border-color: rgba(16, 16, 16, 0.3);
  border-width: 1px;
}

.salvar-opcao {
  width: 100%;
  height: 35px;
  text-align: center;
  padding: 5px 0;
  border: 1px solid #000;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 5px;
}

.salvar-opcao:hover {
  background: #eaeaea;
}
.login-container {
    display: flex;
}

.login-background {
    width: 100vw;
    height: 100vh;
}

.login-logo-container img {
    margin: 55px 0 0 45px;
    width: 220px;
}

.login_title {
    color: #fff;
    margin: 55px 0 0 0;
    padding-left: 45px;
    width: 470px;
    height: 80px;
    font-size: 45px;
    font-weight: 300;
    background-color: rgb(0, 52, 136);
}

.login_subtitle {
    color: #fff;
    margin: 55px 0 0 45px;
}

.login-overlay-load {
    height: 350px;
    width: 100%;
    align-items: center;
    
    display: flex;
}

.login-form-wrapper {
    background: #FFFFFF;
    height: 100vh;
    width: 770px;
    margin-left: auto;
    padding: 30px 0px;
    align-items: center;
    display: flex;
}

.login-form {
    margin: auto;
    width: 65%;
    height: 60%;
    min-height: 435px;
}

.login-form h3 {
    font-size: 38px;
}

.login-form form {
    height: 100%;
}

.login-form form label {
    width: 100%;
    font-size: 13px;
    padding-left: 7px;
    margin-top: 35px;
}

.login-form form input {
    border: none;
    border-bottom: 1px solid #BBBBBB;
    font-size: 15px;
    height: 35px;
    width: 100%;
    padding-left: 7px;
}

.login-form-fields {
    height: 350px;
    width: 100%;
    padding: 60px 0;
    display: inline-block;
}

.login-form-fields div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.botao-entrar {
    margin-bottom: auto;
    width: 100%;
    background-color:#fff;
    border: 1px solid #BBBBBB;
    border-radius: 0px;
    height: 37px;
}

.botao-entrar:hover {
    background-color:#dfdfdf;
}

.botao-entrar:active {
    background: #fff;
}

.input_senha {
    display: flex;
    flex-direction: row !important;
}

.alterna_visibilidade  {
    padding: 0 10px;
    border-style: none none solid none;
    border-width: 1px;
    border-color: #BBBBBB;
    background: #fff;
}

@media (max-width: 600px) {
    .login-form {
        background: #fff;
        padding: 35px 25px;
        height: 80% !important;
        border-radius: 10px;
    }

    .login-form h3{
        color: #000 !important;
    }

    .login-form label{
        color: #000 !important;
    }

    .login-form-fields {
        height: 300px;
        padding-top: 40px;
    }

    .login-logo-container  {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .login-logo-container img {
        margin: auto;
        margin-top: 40px;
        width: 210px;
    }

    .login-form-wrapper {
        position: absolute;
        top: 18%;
        padding: 0;
        height: 550px;
        width: 100%;
        background: rgba(255, 255, 255, 0.0);
    }

    .login-form {
        height: 100%;
        width: 85%;
    }

    .login-form h3 {
        font-size: 34px;
        color: #fff;
    }
    
    .login-form form label {
        color: #cacaca;
    }

    .login-form form input {
        border-bottom: 1px solid #cacaca;
    }
    
    .login-form form input {
        color: #fff;
        background: none;
    }

    ::placeholder {
        color: #fff;
    }
}
.summary-wrap {
    padding: 25px 0;
    display: flex;
    font-size: 16px;
    flex-wrap: wrap;
}

.summary1-table-wrap table,
.summary2-table-wrap table {
    border: solid 1px #c0c0c0;
    border-radius: 5px;
    padding: 8px;
    text-align: left;
}

.summary1-table-wrap p,
.summary2-table-wrap p {
    margin-left: 8px;
    margin-bottom: 8px;
}

.collapse {
    border-spacing: 0;
    padding: 12px !important;
}

.collapse td {
    padding: 1.5px;
}

.summary1-table-wrap table th:nth-child(1) {
    min-width: 135px;
}

.summary2-table-wrap table th:nth-child(1) {
    width: 115px;
}

.summary1-table-wrap table th,
.summary2-table-wrap table th {
    padding-bottom: 6px;
}


.summary1-table-wrap th:nth-child(2),
.summary1-table-wrap th:nth-child(3),
.summary1-table-wrap th:nth-child(4),
.summary2-table-wrap th:nth-child(2),
.summary2-table-wrap th:nth-child(3),
.summary2-table-wrap th:nth-child(4) {
    text-align: center;
}

.summary1-table-wrap table td:nth-child(2),
.summary1-table-wrap table td:nth-child(3),
.summary1-table-wrap table td:nth-child(4),
.summary2-table-wrap table td:nth-child(2),
.summary2-table-wrap table td:nth-child(3),
.summary2-table-wrap table td:nth-child(4) {
    width: 145px;
    text-align: center;
}

.summary2-table-wrap {
    margin-left: 80px;
}

.linha-background-cinza {
    background-color: #EAEAEA;
}

@media only screen and (max-width: 600px) {
    .summary1-table-wrap {
        overflow-x: auto;
    }

    .summary2-table-wrap {
        margin-top: 20px;
        margin-left: 0px;
        overflow-x: auto;
    }
}
.type1-element {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.type1-top-line {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
}

.type1-cliente-top-line {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
}

.type1-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    width: 270px;
    border-bottom: 3px solid #C4C4C4;
    line-height: 36px;
    padding-left: 0;
}

.type1-element-link {
    color: inherit;
    text-decoration: inherit;
}

.type1-main-button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    margin-bottom: 5px;
    transition: background 0.2s;
    font-size: 18px;
}

.type1-main-button:hover {
    background: #eaeaea;
}

.type1-main-button:active {
    background: #fff;
}

.type1-main-button span{
    margin-left: 8px;
    font-size: 13.3px;
}


.type1-cliente-main-button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    margin-bottom: 5px;
    transition: background 0.2s;
    font-size: 18px;
}

.type1-cliente-main-button:hover {
    background: #eaeaea;
}

.type1-cliente-main-button:active {
    background: #fff;
}

.type1-cliente-main-button span{
    margin-left: 8px;
    font-size: 13.3px;
}

.tabela-clientes {
    width: 99%;
    border-collapse: collapse;
    margin-left: 1%;
}

.type1-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type1-content th:nth-child(1) {
    text-align: left;
    width: 80px;
}

.type1-content th:nth-child(2) {
    text-align: left;
    width: 350px;
}

.type1-content th:nth-child(3) {
    text-align: left;
    width: 180px;
}

.type1-content th:nth-child(4) {
    text-align: left;
    width: 220px;
}

.type1-content td {
    padding: 5px 0;
}

.type1-content tr {
    transition: background 0.2s;
}

.type1-content tr:hover {
    background: #eaeaea;
}


.type1-cliente-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type1-cliente-content th:nth-child(1) {
    text-align: left;
    width: 80px;
}

.type1-cliente-content th:nth-child(2) {
    text-align: left;
    width: 350px;
}

.type1-cliente-content th:nth-child(3) {
    text-align: left;
    width: 180px;
}

.type1-cliente-content th:nth-child(4) {
    text-align: left;
    width: 220px;
}

.type1-cliente-content td {
    padding: 5px 0;
}

.type1-cliente-content tr {
    transition: background 0.2s;
}

.type1-cliente-content tr:hover {
    background: #eaeaea;
}

.type1-functions {
    width: 245px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.type2-functions {
    width: 200px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.new-proposal1 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 135px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal1:hover {
    background: #1B6C1E;
}


.new-proposal2 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 155px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal2:hover {
    background: #1B6C1E;
}



@media (max-width: 600px) {
    .type1-cliente-main-button{
        font-size: 26px;
    }

    .type1-cliente-main-button span{
        margin-left: 4px;
        font-size: 13.3px;
    }

    .type1-cliente-content {
        max-height: 100%;
    }

    .type1-cliente-top-line {
        flex-wrap: wrap;
        height: 140px;
    }

    .type1-cliente-main-button {
        margin: -5px 0 5px 0;
    }

    .type1-cliente-content th:nth-child(1) {
        min-width: 50px;
    }

    .type1-cliente-content th:nth-child(2) {
        min-width: 200px;
    }

    .type1-cliente-content th:nth-child(3) {
        min-width: 150px;
    }

    .type1-cliente-content th:nth-child(4) {
        min-width: 125px;
    }
}
.input-group {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    justify-content: space-between;
    align-items: center;
}

/* .label-client-radio {
    min-width: 50px;
    padding-left: 8px;
    padding-right: 50px;
} */

.label-client-text {
    font-weight: 600;
    padding-right: 10px;
}

.text-input {
    width: 100%;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
}

.type3-content form textarea {
    width: 100%;
    resize: vertical;
    min-height: 140px;
    height: 60px;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 16px 24px;
    line-height: 24px;
}

.type3-content {
    width: 100%;
    margin: auto;
    padding: 20px 25px;
}

.dados-cliente-wrapper-botoes {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content:flex-end;
    color: white;
}

.dados-cliente-wrapper-botoes button {
    margin-left: 40px;
}

.dados-cliente-botao-composto {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 160px;
    background: #0083cc;
    transition: background 0.2s;
}

.dados-cliente-botao-composto:hover {
    background: #005a8c;
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 120px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

/* .type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 80px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 300px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
} */

.type3-content th:nth-child(7) {
    min-width: 115px;
}

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.proposal-element-narrow-wrapper-sistemas {
    width: 27%;
    min-height: 450px;
}

.proposal-element-wide-wrapper-sistemas {
    width: 70%;
    min-height: 450px;
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 230px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 70px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 200px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
}

.type3-content th:nth-child(7) {
    min-width: 140px;
}

.type3-content th:nth-child(8) {
    min-width: 140px;
}

/* .type3-content th:nth-child(9) {
    min-width: 220px;
} */

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}

.proposals-table select {
    font-weight: bold;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 150px;
    padding: 0px 5px;
    text-align: center;
}
.proposal-container {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.proposal-client-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 18px;
    padding: 0px 15px;
}

.proposal-client-title table {
    border-collapse: collapse;
    border: none;
}

.proposal-client-title th {
    border: none;
    text-align: left;
}

.proposal-client-title td {
    padding-right: 30px;
    padding-left: 25px;
    min-width: 180px;
    border: none;
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
}
.proposal-element-body-functions {
    width: 55%;
    min-width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button {
    width: 24%;
    min-width: 105px;
}


.proposal-button button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
}

.proposal-button button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}


.proposal-element-body-functions span {
    padding: 6px 9px;
    margin: 0 auto;
}

.proposal-element-body-functions button:hover {
    background: #eaeaea;
}

.proposal-element-body-functions button:active {
    background: #fff;
}

.proposal-element-narrow {
    width: 22%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.proposal-element-narrow-wrapper {
    width: 22%;
    min-height: 450px;
}

.proposal-element-narrow-wrapper form {
    height: 100%;
}

.proposal-subelement {
    width: 100%;
    height: calc(100% - 32px);
    min-height: 418px;
    background: white;
    border-radius: 0  0 5px 5px;
    padding: 15px 25px 20px;
}

.proposal-element-wide {
    width: 54%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 0px auto;
}

.proposal-element-wide-wrapper {
    width: 53%;
    min-height: 450px;
}

.proposal-element-title {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.proposal-element-title button {
    padding: 2px;
    border: none;
    background: inherit;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
}

.proposal-element-body {
    padding: 20px 0px;
}


.input-group-2 {
    display: block;
    margin-bottom: 7px;
}

.input-group-narrow-2 {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.input-group-narrow-2-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.form-simulation-desconto-weg {
    margin-bottom: 7px;
}

.form-simulation-desconto-weg-input {
    display: flex;
    justify-content: space-between;
}

.form-simulation-desconto-weg-input div {
    width: 22%;
}

.alert-save {
    margin-top: 15px;
    text-align: center;
}
.proposal-element-body label {
    font-size: 12px;
    margin-left: 4px;
}

.proposal-element-body input {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}



.borda-alteracao {
    border: 1px solid #d87000 !important;
}





.submit-input {
    height: 35px;
    width: 100%;

    background: #fff;

    border-radius: 5px;
    border: 1px solid #000;

    display: flex;
    align-items: center;
    margin-top: 20px;
}

.submit-input p {
    margin-right: 5px;
}

.submit-input div {
    margin: 0px auto;

    display: flex;
    align-items: center;
}

.submit-input:hover {
    background: #eaeaea;
}







.proposal-element-body span {
    margin-left: -75px;
    font-size: 12px;
}

.proposal-element-body select {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}

.select-narrow {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.select-narrow-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.proposal-element-body {
    font-size: 12px;
}

.proposal-element-body table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.proposal-element-body table,
.proposal-element-body th,
.proposal-element-body td {
    border: 1px solid #BBBBBB;
    text-align: center;
}

.proposal-element-body th {
    font-weight: 600;
    padding: 5px;
}

.proposal-element-body td {
    padding: 5px;
}

.table-kit th:nth-child(2),
.table-kit td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
}


.table-kit-narrow {
    width: 16%;
}

.table-50p {
    width: 50%;
}

.table-results1 th {
    width: 50%;
    text-align: left;
    padding-left: 10px;
}

.table-results2 th {
    width: 50%;
}

.table-results3 th:nth-child(1) {
    width: 28%;
}

.proposal-same-line-tables{
    display: flex;
}

.proposal-table-dimensioning {
    width: 35%;
}

.proposal-table-dimensioning th {
    text-align: left;
}

.proposal-table-dimensioning td {
    width: 35%;
}


.proposal-table-total {
    margin-left: auto;
    width: 32%;
}




.type1-title-selection {
    /* margin-left: auto; */
    margin-right: 15px;
    height: 100%;
}

.type1-title-message {
    margin-left: auto;
    height: 100%;
}

.select-table {
    height: 30px;
    width: 190px;
}

.select-table form,
.select-table form select {
    margin-top: 0;
    height: 100%;
    width: 100%;
}

.select-table form select {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
}

.select-loading-wrapper {
    height: 30px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.select-loading {
    height: 30px;
    width: 30px;
    margin: auto;
}

.tabela-diferente {
    border-color: #efab00 !important;
}

.button3{
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3:hover {
    background: #eaeaea;
}

.button3-digitar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #efc700;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
}

.button3-digitar:hover {
    background: #c3a300;
}

.button3-restaurar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #d23a00;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3-restaurar:hover {
    background: #a02d01;
}

.alerta_formulario {
    margin-left: 5px;
    color: #ff0000;
}

.secao-formulario {
    margin-top: 27px;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}


.titulo_autotransformadores {
    padding-top: 30px;
    margin-top: 30px;
}

.valor_final {
    background: #cfcfcf;
}

.valor_final_alternativo {
    background: #f5f5f5;
}

.destaque_ganhos {
    background: #e7ffe6;
}

.wrapper-coluna-campos {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.coluna-campos {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.resumo-invalido {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #cc0000;
}
.react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
    height: 32px;
    max-width: 100%;
  }
  
  .react-tabs__tab {
    border-radius: 5px 5px 0 0;
    background: #C4C4C4;

    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .espaco {
    width: 55%;
  }
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 120px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

/* .type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 80px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 300px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
} */

.type3-content th:nth-child(7) {
    min-width: 115px;
}

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.icone-desatualizado {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
}

.icone-ajuda-desatualizado {
    margin-left: 5px;
}




.esconder-ajuda-desatualizado {
    display: none;
}

.icone-ajuda-desatualizado:hover+.esconder-ajuda-desatualizado {
    display: block;
    position: absolute;
    margin-left: 200px;
    background-color: #fff;
    border: 2px solid #0083cc;
    border-radius: 5px;
    padding: 5px 10px;
}

.icone-ajuda-desatualizado:hover+.esconder-ajuda-desatualizado:after {
    content: '';
    position: absolute;
    transform: rotate(270deg); /* Equal to rotateZ(45deg) */
    bottom: 10px;
    left: -5px;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #0083cc;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

.icone-ajuda-desatualizado {
    cursor: pointer;
}
.proposal-container {
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
}

.proposal-client-title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 18px;
    padding: 0px 15px;
}

.proposal-client-title table {
    border-collapse: collapse;
    border: none;
}

.proposal-client-title th {
    border: none;
    text-align: left;
}

.proposal-client-title td {
    padding-right: 30px;
    padding-left: 25px;
    min-width: 180px;
    border: none;
    text-align: left;
    padding-top: 3px;
    padding-bottom: 3px;
}
.proposal-element-body-functions-resumida {
    width: 40%;
    min-width: 330px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-resumida {
    width: 32%;
    min-width: 105px;
}


.proposal-button-resumida button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
}

.proposal-button-resumida button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}


.proposal-element-body-functions-resumida span {
    padding: 6px 9px;
    margin: 0 auto;
}

.proposal-element-body-functions-resumida button:hover {
    background: #eaeaea;
}

.proposal-element-body-functions-resumida button:active {
    background: #fff;
}

.proposal-element-narrow {
    width: 22%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.proposal-element-narrow-wrapper {
    width: 22%;
    min-height: 450px;
}

.proposal-element-narrow-wrapper form {
    height: 100%;
}

.proposal-subelement {
    width: 100%;
    height: calc(100% - 32px);
    min-height: 418px;
    background: white;
    border-radius: 0  0 5px 5px;
    padding: 15px 25px 20px;
}

.proposal-element-wide {
    width: 54%;
    min-height: 450px;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 0px auto;
}

.proposal-element-wide-wrapper {
    width: 53%;
    min-height: 450px;
}

.proposal-element-title {
    font-size: 16px;
    display: flex;
    align-items: center;
}

.proposal-element-title button {
    padding: 2px;
    border: none;
    background: inherit;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
}

.proposal-element-body {
    padding: 20px 0px;
}


.input-group-2 {
    display: block;
    margin-bottom: 7px;
}

.input-group-narrow-2 {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.input-group-narrow-2-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.form-simulation-desconto-weg {
    margin-bottom: 7px;
}

.form-simulation-desconto-weg-input {
    display: flex;
    justify-content: space-between;
}

.form-simulation-desconto-weg-input div {
    width: 22%;
}

.alert-save {
    margin-top: 15px;
    text-align: center;
}
.proposal-element-body label {
    font-size: 12px;
    margin-left: 4px;
}

.proposal-element-body input {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}







.submit-input {
    height: 35px;
    width: 100%;

    background: #fff;

    border-radius: 5px;
    border: 1px solid #000;

    display: flex;
    align-items: center;
    margin-top: 20px;
}

.submit-input p {
    margin-right: 5px;
}

.submit-input div {
    margin: 0px auto;

    display: flex;
    align-items: center;
}

.submit-input:hover {
    background: #eaeaea;
}







.proposal-element-body span {
    margin-left: -75px;
    font-size: 12px;
}

.proposal-element-body select {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    padding: 0px 5px;
    font-size: 12px;
}

.select-narrow {
    width: 45%;
    display: inline-block;
    margin-bottom: 7px;
}

.select-narrow-right {
    width: 45%;
    display: inline-block;
    margin-left: 10%;
    margin-bottom: 7px;
}

.proposal-element-body {
    font-size: 12px;
}

.proposal-element-body table {
    width: 100%;
    border-collapse: collapse;
    margin: 10px 0;
}

.proposal-element-body table,
.proposal-element-body th,
.proposal-element-body td {
    border: 1px solid #BBBBBB;
    text-align: center;
}

.proposal-element-body th {
    font-weight: 600;
    padding: 5px;
}

.proposal-element-body td {
    padding: 5px;
}

.table-kit th:nth-child(2),
.table-kit td:nth-child(2) {
    text-align: left;
    padding-left: 10px;
}


.table-kit-narrow {
    width: 16%;
}

.table-50p {
    width: 50%;
}

.table-results1 th {
    width: 50%;
    text-align: left;
    padding-left: 10px;
}

.table-results2 th {
    width: 50%;
}

.table-results3 th:nth-child(1) {
    width: 28%;
}

.proposal-same-line-tables{
    display: flex;
}

.proposal-table-dimensioning {
    width: 35%;
}

.proposal-table-dimensioning th {
    text-align: left;
}

.proposal-table-dimensioning td {
    width: 35%;
}


.proposal-table-total {
    margin-left: auto;
    width: 32%;
}




.type1-title-selection {
    /* margin-left: auto; */
    margin-right: 15px;
    height: 100%;
}

.type1-title-message {
    margin-left: auto;
    height: 100%;
}

.select-table {
    height: 30px;
    width: 190px;
}

.select-table form,
.select-table form select {
    margin-top: 0;
    height: 100%;
    width: 100%;
}

.select-table form select {
    border: 1px solid;
    border-radius: 5px;
    padding-left: 5px;
}

.select-loading-wrapper {
    height: 30px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
}

.select-loading {
    height: 30px;
    width: 30px;
    margin: auto;
}

.tabela-diferente {
    border-color: #efab00 !important;
}

.alerta-tabela {
    height: 30px;
    color: #efab00;
    display: flex;
    line-height: 30px;
    justify-content: center;
}

.alerta-tabela span {
    margin-top: 6px;
    margin-left: 7px;
}

.button3{
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3:hover {
    background: #eaeaea;
}

.button3-digitar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #efc700;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
    margin-bottom: 15px;
}

.button3-digitar:hover {
    background: #c3a300;
}

.button3-restaurar{
    color: #fff;
    height: 22px;
    font-size: 11px;
    width: 100%;
    background: #d23a00;
    border-radius: 5px;
    border: 1px solid #000;
    text-align: center;
    margin: auto;
}

.button3-restaurar:hover {
    background: #a02d01;
}

.alerta_formulario {
    margin-left: 5px;
    color: #ff0000;
}

.secao-formulario {
    margin-top: 27px;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}


.titulo_autotransformadores {
    padding-top: 30px;
    margin-top: 30px;
}

.valor_destaque_resumo {
    background: #e7e7e7;
}

.destaque_ganhos {
    background: #e7ffe6;
}

.wrapper-coluna-campos {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.coluna-campos {
    width: 45%;
    display: flex;
    flex-direction: column;
}

.resumo-invalido {
    width: 100%;
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #cc0000;
}
.react-tabs {
    -webkit-tap-highlight-color: transparent;
    height: 100%;
  }
  
  .react-tabs__tab-list {
    border-bottom: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
    height: 32px;
    max-width: 100%;
  }
  
  .react-tabs__tab {
    border-radius: 5px 5px 0 0;
    background: #C4C4C4;

    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .react-tabs__tab--selected {
    background: #fff;
    border-color: #aaa;
    color: black;
    border-radius: 5px 5px 0 0;
  }
  
  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }
  
  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }
  
  .react-tabs__tab:focus:after {
    content: "";
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }
  
  .react-tabs__tab-panel {
    display: none;
  }
  
  .react-tabs__tab-panel--selected {
    display: block;
  }
  
  .espaco {
    width: 55%;
  }
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 230px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 70px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 200px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
}

.type3-content th:nth-child(7) {
    min-width: 140px;
}

.type3-content th:nth-child(8) {
    min-width: 140px;
}

/* .type3-content th:nth-child(9) {
    min-width: 220px;
} */

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-estacao-veicular {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-estacao-veicular button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-estacao-veicular {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-estacao-veicular {
    width: 30%;
    min-width: 105px;
}

.proposal-button-estacao-veicular button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-estacao-veicular button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions-gr{
    min-width: 100px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content-gr {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content-gr th:nth-child(1),
.type3-content-gr th:nth-child(2) {
    min-width: 80px;
}
.type3-content-gr td:nth-child(3),
.type3-content-gr th:nth-child(3) {
    min-width: 250px;
}

.type3-content-gr th:nth-child(4),
.type3-content-gr th:nth-child(5),
.type3-content-gr th:nth-child(6) {
    min-width: 135px;
}

.type3-content-gr th:nth-child(7) {
    min-width: 180px;
}

.type3-content-gr th:nth-child(8) {
    min-width: 7px;
}

/* .type3-content-gr th:nth-child(9) {
    min-width: 220px;
} */

.type3-content-gr td {
    padding: 5px 0;
}

.type3-content-gr tr {
    transition: background 0.2s;
}

.type3-content-gr tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-gerador {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-gerador button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-gerador {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-gerador {
    width: 30%;
    min-width: 105px;
}

.proposal-button-gerador button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-gerador button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions-om{
    min-width: 230px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 70px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 200px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
}

.type3-content th:nth-child(7) {
    min-width: 140px;
}

.type3-content th:nth-child(8) {
    min-width: 140px;
}

/* .type3-content th:nth-child(9) {
    min-width: 220px;
} */

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-om {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-om button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-om {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-om {
    width: 30%;
    min-width: 105px;
}

.proposal-button-om button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-om button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}

.wrapper-planos-disponiveis-om {
    display: flex;
    justify-content: space-between;
}

.plano-disponivel-om {
    /* border: 1px solid black; */
    width: 12%;
}

.itens-planos-om {
    width: 40%;
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions-bat{
    min-width: 100px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 70px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 200px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
}

.type3-content th:nth-child(7) {
    min-width: 140px;
}

.type3-content th:nth-child(8) {
    min-width: 140px;
}

/* .type3-content th:nth-child(9) {
    min-width: 220px;
} */

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-bateria {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-bateria button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-bateria {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-bateria {
    width: 30%;
    min-width: 105px;
}

.proposal-button-bateria button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-bateria button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions{
    min-width: 230px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content th:nth-child(1),
.type3-content th:nth-child(2) {
    min-width: 70px;
}
.type3-content td:nth-child(3),
.type3-content th:nth-child(3) {
    min-width: 200px;
}

.type3-content th:nth-child(4),
.type3-content th:nth-child(5),
.type3-content th:nth-child(6) {
    min-width: 100px;
}

.type3-content th:nth-child(7) {
    min-width: 140px;
}

.type3-content th:nth-child(8) {
    min-width: 140px;
}

/* .type3-content th:nth-child(9) {
    min-width: 220px;
} */

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-estacao-veicular {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-estacao-veicular button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-estacao-veicular {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-estacao-veicular {
    width: 30%;
    min-width: 105px;
}

.proposal-button-estacao-veicular button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-estacao-veicular button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}
.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.type4-functions-ac{
    min-width: 100px;
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content-ac {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    /* max-height: 616px; */
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content-ac th:nth-child(1),
.type3-content-ac th:nth-child(2) {
    min-width: 80px;
}
.type3-content-ac td:nth-child(3),
.type3-content-ac th:nth-child(3) {
    min-width: 250px;
}

.type3-content-ac th:nth-child(4),
.type3-content-ac th:nth-child(5),
.type3-content-ac th:nth-child(6) {
    min-width: 135px;
}

.type3-content-ac th:nth-child(7) {
    min-width: 180px;
}

.type3-content-ac th:nth-child(8) {
    min-width: 7px;
}

/* .type3-content-ac th:nth-child(9) {
    min-width: 220px;
} */

.type3-content-ac td {
    padding: 5px 0;
}

.type3-content-ac tr {
    transition: background 0.2s;
}

.type3-content-ac tr:hover {
    background: #eaeaea;
}

.botao-pagina-proposta {
    margin: 0 5px !important;
}
.quantidade-automacao_e_controle {
    margin-top: 4px;
    /* margin-bottom: 5px; */
    display: flex;
    flex-direction: row;
    padding: 0 5px;
    justify-content: space-around;
    line-height: 35px;
    font-size: 14px;
}

.quantidade-automacao_e_controle button {
    width: 20px;
    height: 40px;
    border: none;
    background: #fff;
}

.proposal-element-body-functions-automacao_e_controle {
    width: 45%;
    min-width: 330px;
    display: flex;
    justify-content: space-between;
    color: white;
    margin-left: auto;
}

.proposal-button-automacao_e_controle {
    width: 30%;
    min-width: 105px;
}

.proposal-button-automacao_e_controle button {
    width: 100%;
    padding: 6px 9px;
    height: 35px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
}

.proposal-button-automacao_e_controle button:disabled {
    border: 1px solid rgba(16, 16, 16, 0.3);
}
.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-painel-controle {
    background: white;
    width: calc(20% - (40px / 5));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco1-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-painel-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-painel-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-painel-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-painel-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-painel-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-painel-controle-responsaveis tr td {
    padding: 5px 0;
}

.bloco1-painel-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-painel-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-painel-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.bloco2-painel-controle {
    background: white;
    width: calc(50% - (10px / 2));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco2-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco3-painel-controle {
    background: white;
    width: calc(75% - (10px / 2));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco3-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco4-painel-controle {
    background: white;
    width: 100%;
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco4-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.wrapper-blocos-painel-controle {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-between;
    padding-top: 10px;
}

.wrapper-alterar-lista-vigente {
    display: flex;
    flex-direction: row;
}

.painel-controle-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 190px;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.wrapper-botao-alterar-lista-vigente {
    margin-top: 4px;
    margin-left: 15px;
}

.divisao-dados-empresa {
    margin-top: 6px;
    height: 6px;
    border-top: 1px dashed #c0c0c0;
}



.grupo-dados-empresa {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    align-items: left;
}

.grupo-dados-empresa-row {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    align-items: left;
}

.subtabela-painel-controle {
    display: flex;
    flex-direction: row;
    width: 250px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.subtabela-painel-controle div {
    width: calc((100% / 2) - 5px);
    height: 23px;
    line-height: 23px;
    font-size: 12px;
}
.input-group {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    justify-content: space-between;
    align-items: center;
}

.input-group-margem-reduzida {
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;
}

/* .label-client-radio {
    min-width: 50px;
    padding-left: 8px;
    padding-right: 50px;
} */

.label-client-text {
    font-weight: 600;
    padding-right: 10px;
}

.text-input {
    width: 100%;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
}

.type3-content form textarea {
    width: 100%;
    resize: vertical;
    min-height: 140px;
    height: 60px;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 16px 24px;
    line-height: 24px;
}

.type3-content {
    width: 100%;
    margin: auto;
    padding: 20px 25px;
}

.dados-cliente-wrapper-botoes {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content:flex-end;
    color: white;
}

.dados-cliente-wrapper-botoes button {
    margin-left: 40px;
}

.dados-cliente-botao-composto {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 160px;
    background: #0083cc;
    transition: background 0.2s;
}

.dados-cliente-botao-composto:hover {
    background: #005a8c;
}

.tabela-sem-quebra-linha {
    white-space: nowrap;
}

.painel-controle-top-line2 {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
}

.tabela-generica-content {
    margin: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.tabela-generica-content-2 {
    margin: 15px 0px;
    max-height: calc(115vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.input-group-painel-controle {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    align-items: left;
}

.input-group-painel-controle-altura1 {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    height: 720px;
    overflow-x: auto;
    overflow-y: auto;
}

.painel-controle-input {
    margin: 3px 0px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.h4-painel-controle {
    width: '100%';
    height: 30px;
    line-height: 30px;
    background: #f8f8f8;
    text-align: center;
    margin-bottom: 5px;
}

.input_senha {
    display: flex;
    flex-direction: row !important;
}

.alterna_visibilidade_painel_controle {
    padding: 0 10px;
    /* margin-left: -15px; */
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    background: #fff;
    margin: 3px 0px 3px 5px;
}

.alterna_visibilidade_painel_controle svg {
    margin-top: 4px;
}

.painel-controle-wrapper-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    color: white;
}

.type5-functions {
    width: 80px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}
/* .type1-element {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 20px 25px;
}

.type1-top-line {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #C4C4C4;
}

.type1-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    width: 270px;
    border-bottom: 3px solid #C4C4C4;
    line-height: 36px;
    padding-left: 0;
}

.visualizar-tabela-title {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    width: 370px;
    border-bottom: 3px solid #C4C4C4;
    line-height: 36px;
    padding-left: 0;
}

.type1-element-link {
    color: inherit;
    text-decoration: inherit;
}

.type1-main-button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    margin-bottom: 5px;
    transition: background 0.2s;
    font-size: 18px;
}

.type1-main-button:hover {
    background: #eaeaea;
}

.type1-main-button:active {
    background: #fff;
}

.type1-main-button span{
    margin-left: 8px;
    font-size: 13.3px;
}

.type1-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.tabela-clientes {
    width: 99%;
    border-collapse: collapse;
    margin-left: 1%;
}

.type1-content th:nth-child(1) {
    text-align: left;
    width: 80px;
}

.type1-content th:nth-child(2) {
    text-align: left;
    width: 350px;
}

.type1-content th:nth-child(3) {
    text-align: left;
    width: 150px;
}

.type1-content th:nth-child(4) {
    text-align: left;
    width: 220px;
}

.type1-content td {
    padding: 5px 0;
}

.type1-content tr {
    transition: background 0.2s;
}

.type1-content tr:hover {
    background: #eaeaea;
}

.type1-functions {
    width: 225px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.type2-functions {
    width: 200px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}

.new-proposal1 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal1:hover {
    background: #1B6C1E;
}


.new-proposal2 {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 155px;
    background: #2AAC2F;
    margin: auto;
    transition: background 0.2s;
}

.new-proposal2:hover {
    background: #1B6C1E;
}



@media (max-width: 767px) {
    .type1-main-button{
        font-size: 26px;
    }

    .type1-main-button span{
        margin-left: 4px;
        font-size: 13.3px;
    }

    //.type1-title {
    //    font-size: 22px;
    //    line-height: 32px;
    //}

        
    .type1-content {
        max-height: 100%;
    }
} */
/* CSS pronto */
.input_file {
    outline: 2px dashed #ddd;
    outline-offset: -7px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 25px 40px 50px;
    text-align: center !important;
    margin: 0;
    width: 100%;
}

.input_file:hover{
    cursor: pointer;
    outline: 2px dashed #bbb;
}

.input_file:focus{
    outline: 2px dashed #bbb; 
    outline-offset: -7px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border: 1px solid #ddd;
}

.files {
    position: relative;
}

.files:before {
    position: absolute;
    bottom: -20px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " Ou arraste aqui. ";
    display: block;
    margin: 0 auto;
    color: #aaa;
    font-weight: 600;
    text-align: center;
}




/* CSS próprio */
.form-importar-tabela {
    width: 400px;
}

.input-group-importar {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: left;
}

.importar-input {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.importar-input-textarea {
    margin: 3px 0px;
    height: 250px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    overflow-x: auto;
    overflow-y: auto;
}

.importar-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.wrapper-table-importar {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
}

.table-pre-visualizacao {
    width: 100%;
    font-size: 10px;
    border-collapse: collapse;
    text-align: center;
}


.table-pre-visualizacao td, .table-pre-visualizacao th {
    border: 1px solid #ddd;
    padding: 8px;
}

.table-pre-visualizacao tr:nth-child(even){background-color: #f2f2f2;}

.table-pre-visualizacao tr:hover {background-color: #ddd;}

.table-pre-visualizacao th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #aaa;
    color: white;
}

.importar-content {
    width: 100%;
    margin: auto;
    padding: 15px 0px;
    overflow-x: auto;
    overflow-y: auto;
}

.importar-tabela-vazia {
    font-size: 14px;
}

.wrapper-importar-superior {
    display: flex;
    flex-direction: row;
}

.importar-log {
    margin-left: 120px;
    width: 600px;
}

.exemplo-importar-tabela {
    font-size: 13px;
    color: #bfbfbf;
}

.importar-tabela-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}
.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-controle {
    background: white;
    width: 25%;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 10px 10px 0 0;
}

.bloco1-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-responsaveis tr td {
    padding: 5px 0;
}


.bloco1-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.wrapper-blocos {
    display: flex;
}
.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-controle {
    background: white;
    width: 25%;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 10px 10px 0 0;
}

.bloco1-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-responsaveis tr td {
    padding: 5px 0;
}


.bloco1-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.wrapper-blocos {
    display: flex;
}
.selecao-comparativo {
    width: 670px;
    display: flex;
    justify-content: space-between;
}

.wrapper-botao-comparar-tabela {
    margin-top: 4px;
}

.wrapper-comparativo {
    display: flex;
    justify-content: space-between;
}

.wrapper-table-comparar {
    width: calc(45%);
    overflow-x: auto;
    overflow-y: auto;
}

.wrapper-diferença {
    width: calc(10%);
    overflow-x: auto;
    overflow-y: auto;
    font-size: 14px !important;
}

.aumentoPrecoComparativo {
    background-color: rgb(255, 73, 73);
    color: white;
}

.reducaoPrecoComparativo {
    background-color: rgb(20, 224, 20);
}
.user-wrapper table {
    width: 100%;
    border-collapse: collapse;
}

.user-wrapper th {
    text-align: left;
    width: 190px;
}

.user-wrapper td {
    padding: 5px 0;
}

.user-wrapper {
    display: flex;
    justify-content: space-between;
}

.user-profile {
    line-height: 25px;
}

.user-image {
    width: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-image img {
    height: 170px;
    border: 3px solid #C4C4C4;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.17);
}
.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-controle {
    background: white;
    width: 25%;
    border-radius: 5px;
    padding: 20px 25px;
    margin: 10px 10px 0 0;
}

.bloco1-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-controle-responsaveis tr td {
    padding: 5px 0;
}


.bloco1-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.wrapper-blocos {
    display: flex;
}
