.summary-wrap {
    padding: 25px 0;
    display: flex;
    font-size: 16px;
    flex-wrap: wrap;
}

.summary1-table-wrap table,
.summary2-table-wrap table {
    border: solid 1px #c0c0c0;
    border-radius: 5px;
    padding: 8px;
    text-align: left;
}

.summary1-table-wrap p,
.summary2-table-wrap p {
    margin-left: 8px;
    margin-bottom: 8px;
}

.collapse {
    border-spacing: 0;
    padding: 12px !important;
}

.collapse td {
    padding: 1.5px;
}

.summary1-table-wrap table th:nth-child(1) {
    min-width: 135px;
}

.summary2-table-wrap table th:nth-child(1) {
    width: 115px;
}

.summary1-table-wrap table th,
.summary2-table-wrap table th {
    padding-bottom: 6px;
}


.summary1-table-wrap th:nth-child(2),
.summary1-table-wrap th:nth-child(3),
.summary1-table-wrap th:nth-child(4),
.summary2-table-wrap th:nth-child(2),
.summary2-table-wrap th:nth-child(3),
.summary2-table-wrap th:nth-child(4) {
    text-align: center;
}

.summary1-table-wrap table td:nth-child(2),
.summary1-table-wrap table td:nth-child(3),
.summary1-table-wrap table td:nth-child(4),
.summary2-table-wrap table td:nth-child(2),
.summary2-table-wrap table td:nth-child(3),
.summary2-table-wrap table td:nth-child(4) {
    width: 145px;
    text-align: center;
}

.summary2-table-wrap {
    margin-left: 80px;
}

.linha-background-cinza {
    background-color: #EAEAEA;
}

@media only screen and (max-width: 600px) {
    .summary1-table-wrap {
        overflow-x: auto;
    }

    .summary2-table-wrap {
        margin-top: 20px;
        margin-left: 0px;
        overflow-x: auto;
    }
}