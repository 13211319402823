.inversores_modelo_wrapper {
    position: absolute;
    top: 5048px;
    left: 0;
    width: 100%;
}

.inversores_modelo {
    color: #223B7D;
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_modelo div {
    width: 214px;
    font-weight: bold;
    text-align: center;
}

.inversores_imagem_wrapper {
    position: absolute;
    top: 5076px;
    left: 0;
    width: 100%;
}

.inversores_imagem {
    width: 682px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}

.inversores_imagem img {
    height: 145px;
}

.garantias {
    color: #404040;
    font-weight: bold;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 5298px;
    left: 208px;
    line-height: 42px;
    width: 140px;
}

.garantias li div {
    width: 100%;
    text-align: center;
}

.garantias li div p {
    line-height: 18px;
}

.outras_informacoes {
    color: #404040;
    font-weight: bold;
    font-size: 15px;
    display: inline;
    position: absolute;
    top: 5294px;
    left: 615px;
    line-height: 57px;
    width: 90px;
}

.outras_informacoes li div {
    width: 100%;
    text-align: center;
}