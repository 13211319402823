.premissa1 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    width: 150px;
    text-align: center;
    position: absolute;
    top: 2355px;
    left: 134px;
    width: 100px;
    text-align: center;
}

.premissa2 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 284px;
    width: 100px;
    text-align: center;
}

.premissa3 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 418px;
    width: 137px;
    text-align: center;
}

.premissa4 {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    position: absolute;
    top: 2355px;
    left: 560px;
    width: 66px;
    text-align: center;
}

.dados_sistema {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2435px;
    left: 342px;
}

.estimativas_unidade {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    display: inline;
    position: absolute;
    top: 2614px;
    left: 342px;
}

.estimativas_valor {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2614px;
    left: 360px;
    display: inline;
    width: 120px;
    text-align: right;
}

.estimativas_valor li div {
    width: 100%;
    text-align: right;
}

.analise_investimento_unidade {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    display: inline;
    position: absolute;
    top: 2810px;
    left: 342px;
}

.analise_investimento_valor {
    color: #044875;
    font-weight: bold;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2810px;
    left: 365px;
    display: inline;
    width: 100px;
    text-align: right;
}

.analise_investimento_valor li div {
    width: 100%;
    text-align: right;
}

.payback {
    color: #044875;
    font-weight: normal;
    font-size: 13.8px;
    line-height: 19.5px;
    position: absolute;
    top: 2850px;
    left: 334px;
    width: 135px;
    text-align: center;
}

.tir_escalonado {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 349px;
    width: 40px;
    text-align: center;
}

.vpl_escalonado_unidade {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 395px;
}

.vpl_escalonado_valor {
    color: #044875;
    font-size: 13.8px;
    line-height: 20px;
    position: absolute;
    top: 2907px;
    left: 410px;
    width: 100px;
    text-align: right;
}

.tma {
    color: #044875;
    font-weight: bold;
    font-size: 15px;
    position: absolute;
    top: 2916px;
    right: 158px;
}

.impacto_ambiental {
    color: #305123;
    font-weight: normal;
    font-size: 13.8px;
    display: inline;
    position: absolute;
    top: 3085px;
    left: 342px;
    text-align: left;
    line-height: 59px;
}