.modal-fundo {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
}

.modal-nova-proposta-branco {
    width: 500px;
    height: 560px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal-editar-proposta-branco {
    width: 350px;
    height: 390px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal4 {
    width: 500px;
    height: 560px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal-conteudo2 {
    padding: 20px;
}

.proposals-table {
    width: 100%;
    border-collapse: collapse;
}

.proposals-table th {
    text-align: center;
}

.proposals-table td {
    text-align: center;
}

.modal-proposta-branco-titulo {
    height: 34px;
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 15px;
}

.wrapper-titulo-proposta-branco {
    border-bottom: 3px solid #C4C4C4;
    padding-right: 15px;
    height: 35px;
}

.botao-fechar-modal {
    line-height: 35px;
    height: 17px;
    width: 17px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: auto;
    border: none;
    background: #a1a1a1;
    transition: background 0.2s;
    color: #ffffff;
}

.botao-fechar-modal:hover {
    background: #970000;
}

.modal-proposta-branco-botoes {
    display: flex;
    justify-content: space-between;
    width: 240px;
    margin-left: auto;
}


.modal-proposta-branco-cancelar {
    border-radius: 5px;
    border: none;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #cc0000;
    transition: background 0.2s;
    color: #ffffff;
}

.modal-proposta-branco-cancelar:hover {
    background: #970000;
}

.modal-proposta-branco-salvar {
    border-radius: 5px;
    border: none;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #2AAC2F;
    transition: background 0.2s;
    color: #ffffff;
}

.modal-proposta-branco-salvar:hover {
    background: #1B6C1E;
}

.modal-proposta-branco-salvar-carregando {
    border-radius: 5px;
    border: 1px solid #cacaca;
    height: 35px;
    width: 105px;
    background: #ffffff;
    display: flex;
    justify-content: center;
}

.modal-proposta-branco-salvar-carregando div {
    height: 100%;
    width: 50px;
}

.modal-proposta-branco-corpo {
    text-align: left;
}









.proposta-branco-edicao-tabela-bloqueada {
    cursor: not-allowed;
}

.proposta-branco-edicao-tabela-bloqueada th {
    color: #717171;
    padding-bottom: 10px;
}

.proposta-branco-edicao-tabela-bloqueada td {
    color: #8c8c8c;
    padding: 0 0 10px 15px;
}

.proposta-branco-edicao-tabela-bloqueada td:nth-child(2) {
    padding-right: 50px;
}

.proposta-branco-edicao-tabela {
    margin-bottom: 25px;
}

.proposta-branco-edicao-tabela th {
    padding: 10px 33px 10px 0;
}

.modal-proposta-branco-edicao-h4 {
    width: 100%;
    text-align: center;
    margin: 10px 0 10px;
    padding: 2px 0;
    border: 1px solid #c1c1c1;
    border-style: solid none;
}



.proposta-branco-edicao-tabela input {
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 12px;
}


.proposta-branco-edicao-tabela select {
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 12px;
}







.modal-proposta-branco-clientes {
    height: 300px;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 15px;
}

.modal-proposta-branco-clientes div {
    height: 35px;
    display: flex;
    align-items: center;
}

.modal-proposta-branco-clientes div input {
    height: 100%;
}

.modal-proposta-branco-clientes div label {
    margin-left: 10px;
}

.modal-proposta-branco-selecao-responsavel {
    display: flex;
    height: 40px;
    margin-bottom: 15px;
    align-items: center;
}

.modal-proposta-branco-selecao-responsavel select {
    margin-left: 30px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 160px;
    padding: 0px 5px;
    font-size: 14px;
}

.type4-functions {
    display: flex;
    justify-content: space-evenly;
}

.view-button1 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.view-button1:hover {
    background: #005A8C;
}

.view-button2 {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    background: #7c7c7c;
    margin: auto;
    transition: background 0.2s;
}

.view-button2:hover {
    background: #5a5a5a;
}

.type3-content {
    padding: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.type3-content td {
    padding: 5px 0;
}

.type3-content tr {
    transition: background 0.2s;
}

.type3-content tr:hover {
    background: #eaeaea;
}

/* Tabela com número da proposta e data da proposta */
.tabela1-proposta-branco th {
    padding-right: 15px;
}

.tabela1-proposta-branco td {
    padding-right: 70px;
}

.tabela1-proposta-branco tr {
    height: 35px;
}

/* Tabela com restante dos dados da proposta em branco */
.tabela2-proposta-branco tr {
    height: 35px;
}

.text-input-search3 {
    width: 100%;
    margin: 10px auto 10px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
}

.modal-fundo-visivel {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.0);
    display: flex;
}

.modal2 {
    width: 400px;
    height: 420px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}


.modal3 {
    width: 500px;
    height: 550px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgba(118, 118, 118, 0.8);
}

.modal3 h4 {
    margin-bottom: 10px;
}

.modal-titulo {
    font-size: 17px;
    color: #000;
    font-weight: bold;
    width: 100%;
    text-align: center;
    padding: 30px 0;
}

.modal-conteudo {
    font-size: 14px;
    text-align: center;
    color: #000;
    width: 100%;
    padding: 10px 45px;
    margin-bottom: 35px;
}

.modal-acao {
    width: 65%;
    margin: auto;
    display: flex;
    justify-content: space-around;
}

.modal-botao {
    width: 105px;
    padding: 6px 9px;
    height: 30px;
    line-height: 15px;
    ;
    border: 1px solid #000;
    text-align: center;
    background: #fff;
    border-radius: 5px;
}

.modal-header {
    height: 33px;
    display: flex;
    border-bottom: 1px solid #C4C4C4;
    margin-bottom: 15px;
}

.wrapper-titulo {
    border-bottom: 3px solid #C4C4C4;
    padding-right: 15px;
    height: 34px;
}

.modal-body {
    text-align: left;
    height: 340px;
    overflow-x: auto;
    overflow-y: auto;
}

.modal-body table {
    width: 100%;
}

.modal-body table td {
    height: 35px;
}

.modal-body table td:nth-child(1) {
    width: 300px;
}

.modal-body tr {
    transition: background 0.2s;
}

.modal-body tr:hover {
    background: #eaeaea;
}

.modal-body table button {
    margin: auto;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.modal-body table button:hover {
    background: #eaeaea;
}

.modal-body table button:active {
    background: #fff;
}

.modal-nova-proposta-cadastro-cliente {
    margin-top: 30px;
    padding-right: 18px;
    width: 100%;
    text-align: right;
    font-size: 11px;
}

.modal-nova-proposta-cadastro-cliente a {
    color: #0083cc;
}

.modal-form {
    width: 500px;
    height: 430px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cliente {
    width: 500px;
    height: 430px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cliente form {
    margin-top: 25px;
}

/* .modal-cliente-entrada {
    display: flex;
    height: 40px;
    margin-bottom: 15px;
    align-items: center;
}

.modal-cliente-entrada label {
    font-size: 12px;
    margin-left: 4px;
}

.modal-cliente-entrada select input {
    margin: 3px 0px;
    height: 25px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    flex-grow: 1;
    padding: 0px 5px;
    font-size: 12px;
} */

.modal-cliente-input {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 70%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.modal-cliente-input-narrow {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 55%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.modal-cliente-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 70%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.modal-cliente-radio-grupo {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
}

.modal-cliente-radio {
    align-items: center;
    display: flex;
}

.modal-cliente-radio label {
    margin-left: 5px;
}


.modal-cliente-submit-carregando {
    border-radius: 5px;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 105px;
    background: #fff;
    transition: background 0.2s;
    border: 1px solid rgba(16, 16, 16, 0.3)
}

.modal-cliente-submit-carregando:hover {
    background: #eaeaea;
}


/* MODAL SALVAR COMO */
.modal-salvar-como {
    width: 500px;
    height: 240px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-salvar-como-form-wrapper {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.modal-salvar-como-input-group {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    align-items: center;
}

.modal-salvar-como-input {
    margin: 0 0 0 10px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 110px;
    padding: 0px 5px;
    font-size: 14px;
    text-align: center;
}


.modal-salvar-como-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
}

.modal-salvar-como-botoes button {
    margin-left: 40px;
    color: #fff;
}

.modal-corpo-editar-informacoes-comuns {
    height: 290px;
    overflow-y: auto;
    margin-top: -5px;
}

.modal-editar-informacoes-comuns-wrapper-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: white;
}

.modal-editar-informacoes-comuns-wrapper-botoes button {
    margin-left: 40px;
}

.esconder-ajuda {
    display: none;
}

.icone-ajuda:hover+.esconder-ajuda {
    display: block;
    position: absolute;
    /* color: red; */
    /* left: 0; */
    /* top: 0; */
    margin-top: -37px;
    margin-left: 350px;
    background-color: #fff;
    border: 2px solid #0083cc;
    border-radius: 5px;
    padding: 5px 10px;
}

.icone-ajuda:hover+.esconder-ajuda:after {
    content: '';
    position: absolute;
    transform: rotate(180deg); /* Equal to rotateZ(45deg) */
    bottom: -10px;
    left: 35px;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #0083cc;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

.icone-ajuda {
    color: #dcdce6;
    height: 100%;
    padding: 4px;
    margin: auto 0;
    margin-left: 5px;
    cursor: pointer;
}

.modal-cadastrar-novo-usuario {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal-form-2 {
    width: 1050px;
    height: 750px;
    background-color: #fff;
    margin: auto;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    box-shadow: -0.5px 1px 5px rgb(118 118 118 / 80%);
}

.modal-cadastrar-novo-usuario-bloco1 {
    width: 55%;
}

.modal-cadastrar-novo-usuario-bloco2 {
    width: 35%;
    padding: 20px;
    border: 1px solid #C4C4C4;
}

.previsualizacao-perfil {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.previsualizacao-perfil img {
    width: 170px;
    height: 170px;
    border: 3px solid #C4C4C4;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.17);
}

.modal-cadastrar-novo-usuario-base {
    width: 100%;
    padding-top: 20px;
}