.identificacao_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    left: 52px;
}

.data_proposta {
    font-weight: normal;
    font-size: 21px;
    color: #746E6E;
    position: absolute;
    top: 125px;
    right: 90px;
    text-align: right;
}

.cliente_economia {
    font-weight: normal;
    font-size: 26px;
    color: #746E6E;
    position: absolute;
    top: 408px;
    left: 52px;
}

.lista_resumo_capa {
    font-weight: normal;
    font-size: 21px;
    line-height: 35px;
    color: #fff;
    position: absolute;
    top: 556px;
    left: 61px;
    width: 350px;
    height: 550px;
    display: inline;
}

.lcoe {
    font-weight: normal;
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: 623px;
    right: 155px;
}