.login-container {
    display: flex;
}

.login-background {
    width: 100vw;
    height: 100vh;
}

.login-logo-container img {
    margin: 55px 0 0 45px;
    width: 220px;
}

.login_title {
    color: #fff;
    margin: 55px 0 0 0;
    padding-left: 45px;
    width: 470px;
    height: 80px;
    font-size: 45px;
    font-weight: 300;
    background-color: rgb(0, 52, 136);
}

.login_subtitle {
    color: #fff;
    margin: 55px 0 0 45px;
}

.login-overlay-load {
    height: 350px;
    width: 100%;
    align-items: center;
    
    display: flex;
}

.login-form-wrapper {
    background: #FFFFFF;
    height: 100vh;
    width: 770px;
    margin-left: auto;
    padding: 30px 0px;
    align-items: center;
    display: flex;
}

.login-form {
    margin: auto;
    width: 65%;
    height: 60%;
    min-height: 435px;
}

.login-form h3 {
    font-size: 38px;
}

.login-form form {
    height: 100%;
}

.login-form form label {
    width: 100%;
    font-size: 13px;
    padding-left: 7px;
    margin-top: 35px;
}

.login-form form input {
    border: none;
    border-bottom: 1px solid #BBBBBB;
    font-size: 15px;
    height: 35px;
    width: 100%;
    padding-left: 7px;
}

.login-form-fields {
    height: 350px;
    width: 100%;
    padding: 60px 0;
    display: inline-block;
}

.login-form-fields div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.botao-entrar {
    margin-bottom: auto;
    width: 100%;
    background-color:#fff;
    border: 1px solid #BBBBBB;
    border-radius: 0px;
    height: 37px;
}

.botao-entrar:hover {
    background-color:#dfdfdf;
}

.botao-entrar:active {
    background: #fff;
}

.input_senha {
    display: flex;
    flex-direction: row !important;
}

.alterna_visibilidade  {
    padding: 0 10px;
    border-style: none none solid none;
    border-width: 1px;
    border-color: #BBBBBB;
    background: #fff;
}

@media (max-width: 600px) {
    .login-form {
        background: #fff;
        padding: 35px 25px;
        height: 80% !important;
        border-radius: 10px;
    }

    .login-form h3{
        color: #000 !important;
    }

    .login-form label{
        color: #000 !important;
    }

    .login-form-fields {
        height: 300px;
        padding-top: 40px;
    }

    .login-logo-container  {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .login-logo-container img {
        margin: auto;
        margin-top: 40px;
        width: 210px;
    }

    .login-form-wrapper {
        position: absolute;
        top: 18%;
        padding: 0;
        height: 550px;
        width: 100%;
        background: rgba(255, 255, 255, 0.0);
    }

    .login-form {
        height: 100%;
        width: 85%;
    }

    .login-form h3 {
        font-size: 34px;
        color: #fff;
    }
    
    .login-form form label {
        color: #cacaca;
    }

    .login-form form input {
        border-bottom: 1px solid #cacaca;
    }
    
    .login-form form input {
        color: #fff;
        background: none;
    }

    ::placeholder {
        color: #fff;
    }
}