:root {
    /* --bg:  #242526;
    --bg-accent: #484a4d;
    --text-color: #dadce1; */
    --nav-size: 60px;
    --border: 1px solid #919191;
    --border-radius: 8px;
    --speed: 500ms;

    --menu-hover: #e5e5e5;
    --bg:  #ffffff;
    --bg-accent: #eaeaea;
    --text-color: #000000;
  }
  a {
    color: var(--text-color);
    text-decoration: none;
  }
  
  /* Top Navigation Bar */
  
  /* <nav> */
  .fbnavbar {
    height: var(--nav-size);
    padding: 0 1rem;
    border-bottom: var(--border);
  }
  
  /* <ul> */
  .fbnavbar-nav {
    max-width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  /* <li> */
  .nav-item {
    display: flex;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* margin-left: auto; */
  }
  
  /* Icon Button */
  .icon-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    box-sizing: content-box;
    background-size: 30px 30px;

  }
  
  .icon-button:hover {
    filter: brightness(1.2);
  }

  .icon-button img {
    height: 100%;
    border-radius:50%;
  }
  
  .icon-button svg { 
    /* fill: var(--text-color); */
    width: 20px;
    height: 20px;
  }
  
  
  /* Dropdown Menu */
  
  .dropdown {
    position: absolute;
    top: 59px;
    width: 170px;
    transform: translateX(-40%);
    background-color: var(--bg);
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 0.35rem;
    overflow: hidden;
    transition: height var(--speed) ease;
    box-sizing: content-box;
    z-index: 50;
  }
  
  .menu {
    width: 100%;
  }
  
  .menu-item {
    height: 50px;
    display: flex;
    align-items: center;
    border: none;
    border-radius: var(--border-radius);
    transition: background var(--speed);
    padding: 0.1rem;
    box-sizing: content-box;
    background: #FFFFFF;
  }
  
  .menu-item .icon-button {
    margin-right: 0.5rem;
  }
  
  
  .menu-item .icon-button:hover {
    filter: none;
  }
  
  .menu-item:hover {
    background-color: var(--menu-hover);
  }
  
  .icon-right {
    margin-left: auto;
  }
  
  /* CSSTransition classes  */
  .menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menu-primary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-primary-exit {
    position: absolute;
  }
  .menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all var(--speed) ease;
  }
  
  
  .menu-secondary-enter {
    transform: translateX(110%);
  }
  .menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all var(--speed) ease;
  }
  .menu-secondary-exit {
  
  }
  .menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all var(--speed) ease;
  }

  .background-click {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(126, 126, 126, 0.15)
  }