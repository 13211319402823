@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    /* font-family: Source Sans Pro, sans-serif; */
}

body {
    font: 400 14px Source Sans Pro, sans-serif;
    /* background: #f0f0f5; */
    background: #e6e6eb;
    -webkit-font-smoothing: antialiased;
}

body, input, button, textarea, select {
    font-family: Source Sans Pro, sans-serif;
}

button {
    cursor: pointer;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}


.body-container {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px;
}

.body-container-sem-margem-superior {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 0 40px 5vh;
}

.body-container-margem-inferior-menor {
  width: 100%;
  max-width: 1180px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px 0;
}

.body-container-2-sem-margem-superior {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 0 40px 5vh;
}

.body-container-2 {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px;
}

.body-container-2-margem-inferior-menor {
  width: 100%;
  max-width: 1340px;
  align-items: center;
  margin: auto;
  padding: 5vh 40px 0;
}

.element-row-2 {
  width: 100%;
  max-width: 1340px;
  align-items: top;
  justify-content: space-between;
  display: flex;
  margin: auto;
  padding: 0 40px 40px;
}

.element-row-content-type1 {
  width: 49%;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.button-cancel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #cc0000;
  transition: background 0.2s;
  color: #fff;
}

.button-cancel:hover {
  background: #970000;
}

.button-submit {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #2AAC2F;
  transition: background 0.2s;
  color: #fff;
}

.button-submit:hover {
  background: #1B6C1E;
}

.button-submit-azul {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #0083cc;
  transition: background 0.2s;
  color: #fff;
}

.button-submit-azul:hover {
  background: #005A8C;
}

.button-submit-azul-ajustavel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background: #0083cc;
  transition: background 0.2s;
  color: #fff;
  padding: 0 15px;
  font-size: 12px;
}

.button-submit-azul-ajustavel:hover {
  background: #005A8C;
}

.button-submit-cinza {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #6a6a6a;
  transition: background 0.2s;
  color: #fff;
}

.button-submit-cinza:hover {
  background: rgb(74, 74, 74);
}

.button-submit-cinza-ajustavel {
  border-radius: 5px;
  border: none;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  background: #6a6a6a;
  transition: background 0.2s;
  color: #fff;
  padding: 0 15px;
  font-size: 12px;
}

.button-submit-cinza-ajustavel:hover {
  background: rgb(74, 74, 74);
}

.button-submit-branco {
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #fff;
  transition: background 0.2s;
  border: solid 1px #C4C4C4;
}

.button-carregando {
  border-radius: 5px;
  color: inherit;
  text-align: center;
  line-height: 35px;
  height: 35px;
  width: 105px;
  background: #fff;
  transition: background 0.2s;
  color: #fff;
  border: solid 1px #C4C4C4;
}

.edit1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #0083cc;
  margin: auto;
  transition: background 0.2s;
}

.edit1:hover {
  background: #005A8C;
}

.delete1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #cc0000;
  margin: auto;
  transition: background 0.2s;
}

.delete1:hover {
  background: #970000;
}

.error-form {
  border: 1px solid #cc0000 !important;
}

.botao-filtro-cliente {
  margin-left: 2px;
  border: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0);
}

.text-input-search-wraper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 auto;
}

.text-input-search {
  width: 370px;
  margin: 0 auto 5px;
  height: 30px;
  border: 1px solid #dcdce6;
  border-radius: 5px;
  padding: 0 16px;
}

.text-input-search2 {
  width: 100%;
  margin: 0 auto 15px;
  height: 30px;
  border: 1px solid #dcdce6;
  border-radius: 5px;
  padding: 0 16px;
}



.search1 {
  border-radius: 5px;
  border: none;
  color: #ffffff;
  text-align: center;
  line-height: 44px;
  height: 35px;
  width: 35px;
  background: #828282;
  /* margin: auto; */
  transition: background 0.2s;
}

.search1:hover {
  background: #5a5a5a;
}

.texto-center {
  text-align: center !important;
}

.disabled-button {
  background: #a6a6a6 !important;
  color: #d9d9d9 !important;
}

.icone-actionTitle {
  margin-left: 5px;
  margin-bottom: -5px;
}

.alerta-tabela {
  height: 30px;
  color: #efab00;
  display: flex;
  line-height: 30px;
  justify-content: center;
}

.alerta-tabela span {
  margin-top: 6px;
  margin-left: 7px;
}

.alerta-tabela-tipo2 {
  height: 30px;
  background: #efab00;
  color: #ffffff !important;
  display: flex;
  line-height: 30px;
  justify-content: center;
  padding: 0px 10px;
  border-radius: 5px;
}

.alerta-tabela-tipo2 span {
  margin-top: 6px;
  margin-left: 7px;
}



@media (max-width: 600px) {
  .text-input-search {
    width: 100%;
    margin: 0;
  }

  .text-input-search-cliente {
    width: 100%;
    margin: 0;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
  }

  .text-input-search-wraper {
    width: 100%;
    margin: 10px 0;
  }

  .icone-ajuda {
    display: none;
  }
}