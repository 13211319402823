.type2-content {
    padding: 15px 0px;
    max-height: 616px;
    overflow-x: auto;
    overflow-y: auto;
}

.type2-content table {
    width: 100%;
    border-collapse: collapse;
}

.type2-content th {
    text-align: left;
    width: 190px;
}

.type2-content td {
    padding: 5px 0;
}

.bloco1-painel-controle {
    background: white;
    width: calc(20% - (40px / 5));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco1-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco1-painel-controle-cabecalho button{
    margin-left: auto;
    align-items: center;
    display: flex;
    padding: 6px 9px;
    border-radius: 5px;
    border: 1px solid;
    color: inherit;
    background: inherit;
    transition: background 0.2s;
}

.bloco1-painel-controle-cabecalho button:hover {
    background: #eaeaea;
}

.bloco1-painel-controle-cabecalho button:active {
    background: #fff;
}

.bloco1-painel-controle-responsaveis {
    margin-top: 10px;
    width: 100%;

}

.bloco1-painel-controle-responsaveis tr {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bloco1-painel-controle-responsaveis tr td {
    padding: 5px 0;
}

.bloco1-painel-controle-responsaveis tr td button {
    margin: auto;
    padding: 0 15px;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    height: 35px;
    width: 80px;
    background: #0083cc;
    margin: auto;
    transition: background 0.2s;
}

.bloco1-painel-controle-responsaveis tr td button:hover {
    background: #005A8C;
}


.bloco1-painel-controle-responsaveis tr td button:active {
    background: #0083cc;
}

.bloco2-painel-controle {
    background: white;
    width: calc(50% - (10px / 2));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco2-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco3-painel-controle {
    background: white;
    width: calc(75% - (10px / 2));
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco3-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.bloco4-painel-controle {
    background: white;
    width: 100%;
    border-radius: 5px;
    padding: 20px 25px;
    margin-bottom: 10px;
}

.bloco4-painel-controle-cabecalho {
    display: flex;
    justify-content: space-between;
}

.wrapper-blocos-painel-controle {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: row; */
    justify-content: space-between;
    padding-top: 10px;
}

.wrapper-alterar-lista-vigente {
    display: flex;
    flex-direction: row;
}

.painel-controle-select {
    margin: 3px 0px;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 190px;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
    /* font-family: Source Sans Pro, sans-serif; */
}

.wrapper-botao-alterar-lista-vigente {
    margin-top: 4px;
    margin-left: 15px;
}

.divisao-dados-empresa {
    margin-top: 6px;
    height: 6px;
    border-top: 1px dashed #c0c0c0;
}



.grupo-dados-empresa {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    align-items: left;
}

.grupo-dados-empresa-row {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    align-items: left;
}

.subtabela-painel-controle {
    display: flex;
    flex-direction: row;
    width: 250px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.subtabela-painel-controle div {
    width: calc((100% / 2) - 5px);
    height: 23px;
    line-height: 23px;
    font-size: 12px;
}