.input-group {
    display: flex;
    margin-top: 10px;
    margin-bottom: 35px;
    justify-content: space-between;
    align-items: center;
}

.input-group-margem-reduzida {
    display: flex;
    margin-top: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
    align-items: center;
}

/* .label-client-radio {
    min-width: 50px;
    padding-left: 8px;
    padding-right: 50px;
} */

.label-client-text {
    font-weight: 600;
    padding-right: 10px;
}

.text-input {
    width: 100%;
    height: 35px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    padding: 0 16px;
}

.type3-content form textarea {
    width: 100%;
    resize: vertical;
    min-height: 140px;
    height: 60px;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 16px 24px;
    line-height: 24px;
}

.type3-content {
    width: 100%;
    margin: auto;
    padding: 20px 25px;
}

.dados-cliente-wrapper-botoes {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content:flex-end;
    color: white;
}

.dados-cliente-wrapper-botoes button {
    margin-left: 40px;
}

.dados-cliente-botao-composto {
    border-radius: 5px;
    border: none;
    color: inherit;
    text-align: center;
    line-height: 35px;
    height: 35px;
    width: 160px;
    background: #0083cc;
    transition: background 0.2s;
}

.dados-cliente-botao-composto:hover {
    background: #005a8c;
}

.tabela-sem-quebra-linha {
    white-space: nowrap;
}

.painel-controle-top-line2 {
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
}

.tabela-generica-content {
    margin: 15px 0px;
    max-height: calc(90vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.tabela-generica-content-2 {
    margin: 15px 0px;
    max-height: calc(115vh - 220px);
    overflow-x: auto;
    overflow-y: auto;
}

.input-group-painel-controle {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    align-items: left;
}

.input-group-painel-controle-altura1 {
    display: flex;
    flex-direction: column;
    margin-top: 3px;
    justify-content: space-between;
    height: 720px;
    overflow-x: auto;
    overflow-y: auto;
}

.painel-controle-input {
    margin: 3px 0px;
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    width: 100%;
    /* flex-grow: 0.9; */
    padding: 0px 5px;
    font-size: 14px;
}

.h4-painel-controle {
    width: '100%';
    height: 30px;
    line-height: 30px;
    background: #f8f8f8;
    text-align: center;
    margin-bottom: 5px;
}

.input_senha {
    display: flex;
    flex-direction: row !important;
}

.alterna_visibilidade_painel_controle {
    padding: 0 10px;
    /* margin-left: -15px; */
    height: 30px;
    border: 1px solid #dcdce6;
    border-radius: 5px;
    background: #fff;
    margin: 3px 0px 3px 5px;
}

.alterna_visibilidade_painel_controle svg {
    margin-top: 4px;
}

.painel-controle-wrapper-botoes {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content:flex-start;
    color: white;
}

.type5-functions {
    width: 80px;
    display: flex;
    align-items: center;
    color: white;
    margin-left: auto;
}