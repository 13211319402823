.loading-wraper {
    /* height: 500px; */

}

.loading {
    height: 100%;
    width: 100%;
    max-width: 220px;
    min-width: 200px;



    font-size: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    margin: auto;
    /* border: 1px solid #000; */
}

.loading p{
    margin: auto;
    width: 100%;
    text-align: center;
}