.dynamic-loading-wrapper {
    height: 100%;
    width: 100%;
    margin: auto;
}

.dynamic-loading {
    height: 100%;
    width: 100%;


    background: inherit;
    display: flex;
    align-items: center;
    margin: auto;
}

.white-background {
    background: #ffffff;
    
    border-radius: 5px;
    border: solid 1px #C4C4C4;
    color: inherit;
    text-align: center;
    line-height: 44px;
    height: 35px;
    width: 35px;
    margin: auto;

}