header {
    background: #fff;
    box-shadow: 0 10px 30px rgba(0,0,0,.05);
    width: 100%;
}

.header-top {
    width: 100%;
    max-width: 1340px;
    align-items: center;
    display: flex;
    margin: auto;
    padding: 11px 40px;
}

.header-search {
    width: 300px;
    margin-left: 40px;
}

.header-logo {
    display: flex;
    align-items: center;
}

.header-logo img {
    height: 49px;
}

.header-right-div {
    display: flex;
    width: 100px;
    margin-left: auto;
    align-items: center;
    justify-content: space-between;
}

.header-right-div-single {
    display: flex;
    width: 100px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
}

.header-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 27px;
    width: 27px;
    background: none;
    border: none;
}

.header-user {
    height: 40px;
}

.header-user img {
    height: 100%;
    border-radius: 50%;
}

.header-user-menu {
    width: 150px;
    position: absolute;
    
}

.header-navbar {
    border-top: 1px solid rgba(0,0,0,.05);
}

@media (min-width: 780px) {


}